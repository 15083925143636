import React from "react";
import "./CheckMark.css";

function CheckMark() {
  return (
    <span className="product-selected checkmark">
      <div className="checkmark_circle"></div>
      <div className="checkmark_stem"></div>
      <div className="checkmark_kick"></div>
    </span>
  );
}

export default CheckMark;
