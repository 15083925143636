import React, { useState, useEffect, useRef } from 'react';
import "./Timer.css";

import { setFinalGameWords } from '../../actions/gameActions';
import { setIsTimeOver, setIsLastSeconds } from '../../actions/gameActions';
import { useSelector, useDispatch } from "react-redux";

import Countdown from 'react-countdown';


const Completionist = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsTimeOver(true));
        dispatch(setFinalGameWords());
    }, [dispatch])

    return (
        <h3 style={{ color: "white", marginBottom: "0", textAlign: "center" }} className="screen-header">
            Последнее слово
        </h3>)
}

const renderer = ({ total, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <div className="timer">{total / 1000}</div>;
    }
};


const Timer = (props) => {
    const dispatch = useDispatch();
    const clockRef = useRef();
    const handleStart = () => clockRef.current.start();
    const handlePause = () => clockRef.current.pause();

    const isPause = useSelector((state) => state.gameReducer.isPause);
    const settings = useSelector((state) => state.settings);
    const [milSeconds] = useState(Date.now() + settings.roundDuration * 1000)

    const onLastSeconds = (currentTimeInMilSeconds) => {
        if (currentTimeInMilSeconds === 3000) {
            return dispatch(setIsLastSeconds(true));
        }
    }

    useEffect(() => {
        isPause ? handlePause() : handleStart()
    }, [isPause])

    return (
        <div>
            <Countdown
                date={milSeconds}
                renderer={renderer}
                ref={clockRef}
                onTick={(tik) => onLastSeconds(tik.total)}
            >
            </Countdown>
        </div>
    );
}

export default Timer;