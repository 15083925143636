export const ADD_TEAM = 'ADD_TEAM'
export const REMOVE_TEAM = 'REMOVE_TEAM'
export const EDIT_TEAM_COLOR = 'EDIT_TEAM_COLOR'
export const EDIT_TEAM_AVATAR = 'EDIT_TEAM_AVATAR'
export const EDIT_TEAM_NAME = 'EDIT_TEAM_NAME'
export const SET_TEAM_QUEUE = 'SET_TEAM_QUEUE'
export const ADD_SCORE = 'ADD_SCORE'
export const CHANGE_PLAYING_STATUS = 'CHANGE_PLAYING_STATUS'
export const RESET_TEAMS = 'RESET_TEAMS'
export const ADD_MEMBER = 'ADD_MEMBER'
export const DEL_MEMBER = 'DEL_MEMBER'
export const MIX_MEMBERS = 'MIX_MEMBERS'
export const SET_MEMBER_QUEUE = 'SET_MEMBER_QUEUE'
export const ADD_MEMBER_SCORE = 'ADD_MEMBER_SCORE'

