import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setQueue } from "../actions/queueActions";
import { addScore, setMemberQueue, addMemberScore } from "../actions/teamActions";
import { changePlayingStatus } from "../actions/teamActions";
import { resetAlreadyWords } from "../actions/gameActions";
import { resetGameWords } from "../actions/gameActions";
import { setIsTimeOver, setIsLastSeconds } from "../actions/gameActions";
import { changeStatusAlreadyWords } from "../actions/gameActions";
import { stopAudio } from "../actions/gameActions";

import AnimateBtn from "../components/AnimateBtn";
import Switch from 'react-ios-switch'

const RoundResultsScreen = () => {
  const dispatch = useDispatch();
  const playedWords = useSelector((state) => state.gameReducer.alreadyWords);
  const currentQueuePos = useSelector(state => state.queue)
  const teamsInfo = useSelector((state) => state.teams.teamsInfo);
  const scoreToWin = useSelector(state => state.settings.scoreToWin)

  useEffect(() => {
    dispatch(setIsTimeOver(false))
    dispatch(setIsLastSeconds(false))
    dispatch(stopAudio())
  }, [dispatch])

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onChangeSwitch = (wordIndex, newStatus) => {
    dispatch(changeStatusAlreadyWords(wordIndex, newStatus))
    newStatus ? dispatch(addScore(currentQueuePos, 1)) : dispatch(addScore(currentQueuePos, -1))
  }

  const onNextBtnClick = () => {

    const countPlayerScore = () => {
      return playedWords.filter(wordObj => wordObj.status).length
    }
    dispatch(addMemberScore(currentQueuePos, countPlayerScore()))

    const findFirstIsPlayingTeam = (arr) => {
      let found = false;
      let i = 0;
      while (!found) {
        if (arr[i].isPlaying === true) {
          found = true;
        }
        else i++
      }
      return i;
    }
    const findLastIsPlayingTeam = (arr) => {
      let found = false;
      let i = arr.length - 1;
      while (!found) {
        if (arr[i].isPlaying === true) {
          found = true;
        }
        else i--
      }
      return i;
    }

    let fPlayer = findFirstIsPlayingTeam(teamsInfo);
    const lPlayer = findLastIsPlayingTeam(teamsInfo);
    let nextQueuePos = currentQueuePos;
    let doCycle = true;

    while (doCycle) {
      if (nextQueuePos === lPlayer) {
        const maxScore = Math.max.apply(Math, teamsInfo.map(function (o) { return o.score; }))
        if (maxScore >= scoreToWin) {
          teamsInfo.forEach((el, idx) => {
            if (el.score !== maxScore) {
              dispatch(changePlayingStatus(idx, false))
            }
          });
          fPlayer = findFirstIsPlayingTeam(teamsInfo);
        }
        dispatch(setQueue(fPlayer))
        dispatch(setMemberQueue(currentQueuePos))
        doCycle = false;
      }
      else {
        nextQueuePos += 1;
        if (teamsInfo[nextQueuePos].isPlaying === true) {
          dispatch(setQueue(nextQueuePos))
          dispatch(setMemberQueue(currentQueuePos))
          doCycle = false;
        }
      }
    }

    dispatch(resetAlreadyWords());
    dispatch(resetGameWords());
  }

  return (
    <>
      <div className="content-scroll">
        <h1>Результаты раунда </h1>
        <br />
        {playedWords.map((wordItem, index) => (
          <div key={wordItem.key} className="list-item" >
            <p className="list-item-round-result-p" style={{ fontSize: "1.2rem" }}>{capitalizeFirstLetter(wordItem.word)}</p>
            <Switch checked={wordItem.status} onChange={checked => onChangeSwitch(index, checked)} />
          </div>
        ))}
      </div>

      <div className="btn-section">
        <div onClick={onNextBtnClick} className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn next-btn" style={{ marginLeft: "0" }} to="/score">
              Продолжить
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default RoundResultsScreen;