import {
  GENERATE_GAME_WORDS,
  SET_ALREADY_WORDS,
  RESET_ALREADY_WORDS,
  RESET_GAME_WORDS,
  CHANGE_STATUS_ALREADY_WORDS,
  SET_IS_TIME_OVER,
  SET_IS_LAST_SECONDS,
  SET_FINAL_GAME_WORDS,
  SET_IS_PAUSE,
  SET_INITIAL_AUDIO,
  SET_INITIAL_AUDIO_CHANNEL_TWO,
  PLAY_AUDIO,
  PLAY_AUDIO_CHANNEL_TWO
} from '../constants/gameConstants';
import { v4 as uuidv4 } from 'uuid';
import { silentSound } from "../soundsBase64"
import { getRandomInt } from "../utils/getRandomInt"


export const generateGameWords2 = (selectedSets) => (dispatch) => {
  if (selectedSets.length > 0) {

    const wordsInSetQuantity = 5;
    let generatedWords = []

    while (generatedWords.length !== wordsInSetQuantity) {
      const randomSet = selectedSets[getRandomInt(selectedSets.length)]
      const randomWord = randomSet.words[getRandomInt(randomSet.words.length)]
      const alreadyExist = generatedWords.find((item) => item.word === randomWord)
      !alreadyExist && generatedWords.push({ word: randomWord, name: randomSet.name, key: uuidv4() })
    }

    dispatch({
      type: GENERATE_GAME_WORDS,
      payload: generatedWords
    });
  }
};

export const generateGameWords = (selectedSets, q = 1) => (dispatch) => {
  if (selectedSets.length > 0) {
    const wordsInSetQuantity = q;
    let pointers;
    let randomSet;
    let packName;
    let word
    let generatedWords = []

    pointers = JSON.parse(localStorage.getItem("gameWordsPointers"))
    if (!pointers) {
      pointers = {}
    }

    while (generatedWords.length !== wordsInSetQuantity) {
      randomSet = selectedSets[getRandomInt(selectedSets.length)]
      packName = randomSet.name

      if (pointers[packName] === null || pointers[packName] === undefined) {
       /*  pointers[packName] = getRandomInt(randomSet.words.length - 1) */
        pointers[packName] = 0
      }

      word = randomSet.words[pointers[packName]]

      if (pointers[packName] + 1 >= randomSet.words.length) {
        pointers[packName] = 0
      }
      else {
        pointers[packName] = pointers[packName] + 1
      }

      generatedWords.push({ word: word, name: packName, key: uuidv4() })
    }

    localStorage.setItem("gameWordsPointers", JSON.stringify(pointers));
    dispatch({
      type: GENERATE_GAME_WORDS,
      payload: generatedWords
    });
  }
};

export const setFinalGameWords = () => (dispatch, getState) => {
  const {
    gameReducer: { alreadyWords, gameWords },
  } = getState();

  const lastWord = {
    word: gameWords[gameWords.length - alreadyWords.length - 1].word,
    name: gameWords[gameWords.length - alreadyWords.length - 1].name,
    key: uuidv4()
  }
  
  const newArray = [lastWord]

  dispatch({
    type: SET_FINAL_GAME_WORDS,
    payload: newArray
  });
}

export const resetGameWords = () => (dispatch) => {
  const arr = [];
  dispatch({
    type: RESET_GAME_WORDS,
    payload: arr
  });
}

export const setAlreadyWords = (obj) => (dispatch, getState) => {
  const {
    gameReducer: { alreadyWords },
  } = getState();

  dispatch({
    type: SET_ALREADY_WORDS,
    payload: [...alreadyWords, obj]
  });
}

export const resetAlreadyWords = () => (dispatch) => {
  const arr = [];
  dispatch({
    type: RESET_ALREADY_WORDS,
    payload: arr
  });
}

export const changeStatusAlreadyWords = (wordIndex, newStatus) => (dispatch, getState) => {
  const {
    gameReducer: { alreadyWords },
  } = getState();
  alreadyWords[wordIndex].status = newStatus;

  dispatch({
    type: CHANGE_STATUS_ALREADY_WORDS,
    payload: [...alreadyWords]
  });
}

export const setIsTimeOver = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_TIME_OVER,
    payload: status
  });
}

export const setIsLastSeconds = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_LAST_SECONDS,
    payload: status
  });
}

export const setIsPause = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_PAUSE,
    payload: status
  });
}

/* export const setInitialAudio = () => (dispatch) => {
  const audio = new Audio(silentSound);
  audio.autoplay = true;
  audio.play();
  audio.currentTime = 0;

  dispatch({
    type: SET_INITIAL_AUDIO,
    payload: audio
  });
} */

/* export const setInitialAudioСhannelTwo = () => (dispatch) => {
  const audio = new Audio(silentSound)
  audio.autoplay = true;
  audio.play();
  audio.currentTime = 0;

  dispatch({
    type: SET_INITIAL_AUDIO_CHANNEL_TWO,
    payload: audio
  });
} */

export const setInitialAudio = () => (dispatch, getState) => {
  const {
    gameReducer: { audio },
  } = getState();
 
  if (!audio) {
    const audio = new Audio(silentSound);
    audio.autoplay = true;
    audio.play();
    audio.currentTime = 0;
   
    dispatch({
      type: SET_INITIAL_AUDIO,
      payload: audio
    });
  }
  else return
}

export const setInitialAudioСhannelTwo = () => (dispatch, getState) => {
  const {
    gameReducer: { audioСhannelTwo },
  } = getState();

  if (!audioСhannelTwo) {
    const audio = new Audio(silentSound)
    audio.autoplay = true;
    audio.play();
    audio.currentTime = 0;

    dispatch({
      type: SET_INITIAL_AUDIO_CHANNEL_TWO,
      payload: audio
    });
  }
  else  return
}

export const playAudio = (url, channel = "one") => (dispatch, getState) => {
  const {
    gameReducer: { audio, audioСhannelTwo },
  } = getState();

  switch (channel) {
    case "one":
      if (audio) {
        audio.src = url;
        dispatch({
          type: PLAY_AUDIO,
          payload: audio
        });
      }
      break;
    case "two":
      if (audioСhannelTwo) {
        audioСhannelTwo.src = url;
        dispatch({
          type: PLAY_AUDIO_CHANNEL_TWO,
          payload: audioСhannelTwo
        });
      }
      break;
    default:
      break;
  }
}

export const stopAudio = () => (dispatch, getState) => {
  const {
    gameReducer: { audio, audioСhannelTwo },
  } = getState();

  if (audio && audio.src.includes("+5AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABJbmZvAAAADwAAAFkAAJLvAAUICw4RExYZHBwfIiQnKi0wMzM2ODs+QURHSUlMT1JVWFtdYGBjZmlsbnF0d3d6fX+ChYiLjo6Rk5aZnJ+ipKSnqq2ws7a4u7u+wcTHyczP0tLV2Nvd4OPm6ens7vH09/r9/wAAAABMYXZjNTguMT")) {
    audio.pause();
    audio.currentTime = 0;
  }
  if (audioСhannelTwo && audioСhannelTwo.src.includes("+1DAAAAAAAAAAAAAAAAAAAAAAABJbmZvAAAADwAAAGYAAFQTAAcJDA4RExYYGx0gIiUnKiwvMTQ2OTs+QENFSEpNT1JUVllbXmBjZWhqbW9ydHd5fH6DhoiLjZCSlZeanJ+hpKapq62wsrW3ury/wcTGycvO0NPV2Nrd3+Lk5+ns7vHz9vj7/QAAAABMYXZjNTguMTMAAAAAAAAAAAAAAAAkBAkAAAAAAABUE0XmjGoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")) {
    audioСhannelTwo.pause();
    audioСhannelTwo.currentTime = 0;
  }
}