import {
  GENERATE_GAME_WORDS,
  SET_ALREADY_WORDS,
  RESET_ALREADY_WORDS,
  RESET_GAME_WORDS,
  CHANGE_STATUS_ALREADY_WORDS,
  SET_IS_TIME_OVER,
  SET_IS_LAST_SECONDS,
  SET_FINAL_GAME_WORDS,
  SET_IS_PAUSE,
  SET_INITIAL_AUDIO,
  SET_INITIAL_AUDIO_CHANNEL_TWO,
  PLAY_AUDIO,
  PLAY_AUDIO_CHANNEL_TWO,
} from "../constants/gameConstants";

const initialState = {
  gameWords: [],
  alreadyWords: [],
  isTimeOver: false,
  isLastSeconds: false,
  isPause: false,
  audio: null,
  audioСhannelTwo: null
};

function gameReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GENERATE_GAME_WORDS:
      if (state.gameWords.length === 0) {
        return { ...state, gameWords: payload };
      }
      else {
        return {...state, gameWords: [...payload, ...state.gameWords ] }
      }
    case SET_FINAL_GAME_WORDS:
      return { ...state, gameWords: payload };
    case SET_ALREADY_WORDS:
      return { ...state, alreadyWords: payload };
    case RESET_ALREADY_WORDS:
      return { ...state, alreadyWords: payload };
    case RESET_GAME_WORDS:
      return { ...state, gameWords: payload };
    case CHANGE_STATUS_ALREADY_WORDS:
      return { ...state, alreadyWords: payload };
    case SET_IS_TIME_OVER:
      return { ...state, isTimeOver: payload };
    case SET_IS_LAST_SECONDS:
      return { ...state, isLastSeconds: payload };
    case SET_IS_PAUSE:
      return { ...state, isPause: payload };
    case SET_INITIAL_AUDIO:
      return { ...state, audio: payload };
    case SET_INITIAL_AUDIO_CHANNEL_TWO:
      return { ...state, audioСhannelTwo: payload };
    case PLAY_AUDIO:
      return { ...state, audio: payload };
    case PLAY_AUDIO_CHANNEL_TWO:
      return { ...state, audioСhannelTwo: payload };
    default:
      return state;
  }
}

export default gameReducer;
