import {
  SET_ROUND_DURATION,
  SET_SCORE_TO_WIN,
  SET_MINUS_SCORE,
  SET_LANGUAGE,
  SET_LANDING
} from "../constants/settingsConstants";

export const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ROUND_DURATION:
      return { ...state, roundDuration: action.payload };
    case SET_SCORE_TO_WIN:
      return { ...state, scoreToWin: action.payload };
    case SET_MINUS_SCORE:
      return { ...state, minusScore: action.payload };
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_LANDING:
      return { ...state, viewedLanding: action.payload };
    default:
      return state;
  }
};
