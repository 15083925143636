import { useState, useEffect } from "react";

const useCountAllProductsDiscount = (products, discountPercent) => {

    const [totalPrice, setTotalPrice] = useState()
    const [totalPriceDiscount, setTotalPriceDiscount] = useState()

    useEffect(() => {
        const setsForBuy = products.filter(product => !product.words)
        let total = 0;
        setsForBuy.forEach(product => { total += product.price })
        setTotalPrice(total)
        total = Math.round(total - total * (discountPercent / 100))
        setTotalPriceDiscount(total)
    }, [discountPercent, products])

    return { totalPrice, totalPriceDiscount };
};


export default useCountAllProductsDiscount