import { getRandomInt } from "./getRandomInt";

export const shuffleArr = (arr) => {
    if (Array.isArray(arr)) {
        const initialArray = [...arr];
        const newArr = [];
        let randomIndex;

        while (initialArray.length !== 0) {
            randomIndex = getRandomInt(initialArray.length)
            newArr.push(initialArray[randomIndex])
            initialArray.splice(randomIndex, 1)
        }
        return newArr
    }
    else {
        console.log("%c Err Аргумент в функции shuffleArr должен быть массивом", 'color: #FF0000')
        return
    }
}