import React from 'react';
import "./ProductCard.css";
import "../Product/Product.css"



function ProductCard({ img, name, price, length, testWords }) {
    return (
        <div className='my-card'>
            <div className='my-card-img-container'>
                <img className='my-card-img' src={img} alt={name} />
            </div>
            <div className='my-card-info'>
                <h5>{name}</h5>
                <p style={{ fontSize: "1rem" }}>
                    Количество слов: <span style={{ fontWeight: "600" }}>{length}</span>  <br />
                    Цена: <span style={{ fontWeight: "600" }}>{price} руб.</span>
                </p>
                <div className='card-gift'>
                    <div><img style={{ width: "50px", marginRight: "10px" }} src="/images/gift.png" alt="gift" /></div>
                    <p style={{ margin: "0px" }}>Слова из этого набора на других языках в подарок. Выбрать язык можно в настройках игры.</p>
                </div>
                {
                    testWords && testWords.map((word, index) => (
                        <p key={index} className='chips'>{word.toLowerCase()}</p>
                    ))
                }
                <p className='chips'>и др...</p>


            </div>
        </div>
    );
}

export default ProductCard;