import { SET_QUEUE } from '../constants/queueConstants';

const initialState = 0;

function queue(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_QUEUE:
      return payload;
    default:
      return state;
  }
}

export default queue;
