import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { checkYooOrder } from "../actions/purchaseActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const CheckProductScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const paramsProductId = match.params.id;

  const yoo = useSelector((state) => state.yoo);
  const { loading: yooLoading, error: yooError } = yoo;

  useEffect(() => {
    dispatch(checkYooOrder(paramsProductId, history))
  }, [dispatch, history, paramsProductId]);

  return (
    <>
      <h1>Проверяем покупку</h1>
      {yooError && <>
        <Message variant="danger">Ошибка</Message>
        <Link to="/words">
          <div className="btn tomato">Назад</div>
        </Link>
      </>
      }
      {yooLoading && <Loader />}
    </>
  );
};

export default CheckProductScreen;
