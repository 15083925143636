export const USER_LOGIN_VK_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_VK_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_VK_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE_DATA_LOCALY = "USER_UPDATE_DATA_LOCALY";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_TOOGLE_PRODUCT_REQUEST = "USER_TOOGLE_PRODUCT_REQUEST";
export const USER_TOOGLE_PRODUCT_SUCCESS = "USER_TOOGLE_PRODUCT_SUCCESS";
export const USER_TOOGLE_PRODUCT_FAIL = "USER_TOOGLE_PRODUCT_FAIL";

export const USERS_SUMMARY_REQUEST = "USERS_SUMMARY_REQUEST";
export const USERS_SUMMARY_SUCCESS = "USERS_SUMMARY_SUCCESS";
export const USERS_SUMMARY_FAIL = "USERS_SUMMARY_FAIL";


