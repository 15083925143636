import React from "react";
import { useDispatch } from "react-redux";
import { delTeam } from "../../actions/teamActions";
import { Link } from "react-router-dom";
import AnimateBtn from "../AnimateBtn";

function TeamComponent(props, history) {
  const dispatch = useDispatch();

  const onDelTeam = (e) => {
    e.preventDefault();
    dispatch(delTeam(props.id));
  };

  return (
    <>
      {
        props.width > 0 ?
          <AnimateBtn>
            <Link to={`edit-team/${props.id}`}
              className="team-container"
            >
              <div style={{ height: props.width }} className={"team " + props.color}>
                <img src={props.avatar} className="avatar-img" alt="avatar"></img>
                <div onClick={onDelTeam} className="del-team">
                  <i className="fas fa-times cross"></i>
                </div>
              </div>

              <h6
                className="team-name text-center"
                style={{ maxWidth: props.width }}
              >
                {props.name}
              </h6>

              {props.members && props.members.length > 0 &&
                <p
                  className="team-name text-center"
                  style={{ maxWidth: props.width, color: "grey" }}
                >
                  {props.members.map((member, idx) =>
                    idx !== props.members.length - 1 ?
                      <span key={member.key}>{member.name}, </span> :
                      <span key={member.key}>{member.name} </span>)}
                </p>
              }
            </Link>
          </AnimateBtn>
          : ""
      }
    </>

  );
}

export default TeamComponent;

