import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
/* import TinderCard from "react-tinder-card"; */
import TinderCard from "../react-tinder-card";

import { addScore } from "../../actions/teamActions";
import "./TinderCards.css";
import { playAudio } from "../../actions/gameActions";
import { setAlreadyWords } from "../../actions/gameActions";
import { generateGameWords } from "../../actions/gameActions";
import { yesSound, noSound } from "../../soundsBase64";

import Word from "../Word";


function TinderCards(props) {

    const ref = useRef(null);
    const [w, setW] = useState()

    const dispatch = useDispatch();

    const [isLastSwipe, setIsLastSwipe] = useState(false);
    const currentQueuePos = useSelector(state => state.queue)
    const shouldMinusScore = useSelector(state => state.settings.minusScore)
    const gameWords = useSelector((state) => state.gameReducer.gameWords);
    const alreadyRemoved = useSelector((state) => state.gameReducer.alreadyWords);
    const isTimeOver = useSelector((state) => state.gameReducer.isTimeOver)
    const isPause = useSelector((state) => state.gameReducer.isPause);
    const selectedProducts = useSelector((state) =>
        state.productList.products.filter((product) => product.selected === true)
    );
    const childRefs = useMemo(() => Array(gameWords.length).fill(0).map(i => React.createRef()), [gameWords.length])
   
    //top card
    let topCard;
    if (alreadyRemoved.length === 0) {
        topCard = gameWords.length - 1
    }
    else {
        topCard = gameWords.length - 1 - alreadyRemoved.length
    }
    if (gameWords.length === 1) {
        topCard = 0
    }
    let cardBefore = topCard - 1

    useEffect(() => {
        let isActive = true;
        const swipe = (dir) => {
            /* let cardsLeft = gameWords.filter(item => !alreadyRemoved.some((it) => it.word === item.word) && item.word !== "") */
            let cardsLeft = gameWords.filter(item => !alreadyRemoved.some((it) => it.key === item.key) && item.word !== "")
            if (cardsLeft.length) {
                const toBeRemoved = cardsLeft[cardsLeft.length - 1]
                const index = gameWords.map(item => item).indexOf(toBeRemoved)
                childRefs[index].current.swipe(dir)
            }
        }
        let timer;
        //end round
        if (isTimeOver && isLastSwipe) {
            timer = setTimeout(() => props.history.push("/results"), 300);
        }
        if (props.btnSwipeDir && isActive) {
            swipe(props.btnSwipeDir)
            props.swipeBtnClickHandler(null)
        }
        return () => {
            isActive = false
            clearTimeout(timer);
        };
    }, [props, props.btnSwipeDir, gameWords, alreadyRemoved, isTimeOver, childRefs, isLastSwipe])

    const outOfFrame = (name) => {

    };

    const swiped = (direction, nameToDelete, key) => {
       
        if (direction !== "up" && direction !== "down") {
            if (direction === "left") {
                dispatch(playAudio(noSound))
                shouldMinusScore && dispatch(addScore(currentQueuePos, -1))
                dispatch(setAlreadyWords({ word: nameToDelete, status: false, key }))
                !isTimeOver && dispatch(generateGameWords(selectedProducts))
                isTimeOver && setIsLastSwipe(true)
            }
            else if (direction === "right") {
                dispatch(playAudio(yesSound))
                dispatch(addScore(currentQueuePos, 1))
                dispatch(setAlreadyWords({ word: nameToDelete, status: true, key }))
                !isTimeOver && dispatch(generateGameWords(selectedProducts))
                isTimeOver && setIsLastSwipe(true)
            }
        }
    };

    const renderCardCondition = (item, index) => {
        if (item.word === "") {
            return "t-card hide"
        }
        else {
            switch (index) {
                case topCard:
                    return 'top-card t-card'
                case cardBefore:
                    return 'card-before t-card'
                default:
                    return 't-card hide'
            }
        }
    }

    useEffect(() => {
        ref && ref.current ? setW(ref.current.offsetWidth) : setW(0);
    }, [childRefs])

    return (
        <div className={isPause ? "tinderCards display-none" : "tinderCards "}>
            <div className="tinderCards__cardContainer">
                {gameWords.map((item, index) => (
                    <TinderCard
                        ref={childRefs[index]}
                        className="swipe "
                        key={item.key}
                        preventSwipe={["up", "down"]}
                        onSwipe={(dir) => swiped(dir, item.word, item.key)}
                        onCardLeftScreen={() => outOfFrame(item.word)}
                        topCard={topCard}
                        index={index}
                    >
                        <div ref={ref} className={renderCardCondition(item, index)}>
                            <Word index={index} topCard={topCard} string={item.word} cardWidth={w} />
                            <p className={index === topCard ? 'top-card-unblur card-blur'
                                : 'card-blur'}>{item.name}</p>
                        </div>
                    </TinderCard>
                ))}

            </div>
        </div>
    );
}

export default TinderCards;



/* {gameWords.map((item, index) => (
    <TinderCard
        ref={childRefs[index]}
        className="swipe "
        key={item.key}
        preventSwipe={["up", "down"]}
        onSwipe={(dir) => swiped(dir, item.word)}
        onCardLeftScreen={() => outOfFrame(item.word)}
    >
        <div ref={ref} className={renderCardCondition(item, index)}>
            <Word index={index} topCard={topCard} string={item.word} cardWidth={w} />
            <p className={index === topCard ? 'top-card-unblur card-blur'
                : 'card-blur'}>{item.name}</p>
        </div>
    </TinderCard>
))} */


/* {gameWords.map((item, index) => {
    console.log(index, topCard, "index top card")
    if (index === topCard) {
        return <TinderCard
            ref={childRefs[index]}
            className="swipe "
            key={item.key}
            preventSwipe={["up", "down"]}
            onSwipe={(dir) => swiped(dir, item.word, index)}
            onCardLeftScreen={() => outOfFrame(item.word)}
        >
            <div ref={ref} className={renderCardCondition(item, index)}>
                <Word index={index} topCard={topCard} string={item.word} cardWidth={w} />
                <p className={index === topCard ? 'top-card-unblur card-blur'
                    : 'card-blur'}>{item.name}</p>
            </div>
        </TinderCard>
    }
    else return <TinderCard
        ref={childRefs[index]}
        className="swipe "
        key={item.key}
        preventSwipe={["up", "down", "left", "right"]}
        onSwipe={(dir) => swiped(dir, item.word, index)}
        onCardLeftScreen={() => outOfFrame(item.word)}
    >
        <div ref={ref} className={renderCardCondition(item, index)}>
            <Word index={index} topCard={topCard} string={item.word} cardWidth={w} />
            <p className={index === topCard ? 'top-card-unblur card-blur'
                : 'card-blur'}>{item.name}</p>
        </div>
    </TinderCard>
})} */