import React from 'react';
import "../screens/TeamScreen.css"
import "../screens/EditTeamScreen.css"

function ScoreScreenHeader(props) {
    return (
        <>
            <h2 style={{ paddingBottom: "0" }} className="text-center">{props.header}</h2>
            {props.children}
            <div style={{ margin: "1.2rem 0" }}>
                <div className={`avatar-list-item ${props.color} current-queue-avatar ${props.animationClass}`}>
                    <img className="avatar-img " src={props.avatar} alt="avatar" />
                </div>
                <h5 style={{ margin: "0" }} className="text-center">{props.name} </h5>
            </div>
        </>
    );
}

export default ScoreScreenHeader;