import React from 'react';
import "./Landing.css"

function RulesContent(props) {
    return (
        <>
            <div className="text-center">
                <div className='flex-container mobile-flex-direction'>
                    <div className='left-side-rule mobile-order-1'>
                        <img src="/images/one.svg" alt="Команды" />
                    </div>
                    <div className='right-side-rule rule-text mobile-order-2'>
                        <p>Перед игрой разбейтесь на команды.</p>
                        <p>Задача игрока - объяснять появляющиеся на экране слова своей команде.</p>
                        <p>Задача остальных игроков - отгадать как можно больше слов в течение заданного времени. Если в командах по одному человеку, то объяснять слова будет человек из противоположной команды.</p>
                    </div>
                </div>

                <div className='flex-container mobile-flex-direction'>
                    <div className='left-side-rule rule-text mobile-order-2'>
                        <p>Если слово было отгадано, нажмите синюю кнопку или смахните карточку вправо. Если нет - нажмите красную кнопку или смахните карточку влево. По истечении времени у команды есть возможность отгадать последнее слово.</p>
                        <p>При объяснении не используйте однокоренные слова и переводы на другие языки. Нельзя указывать на предметы или объяснять их по буквам.</p>
                    </div>
                    <div className='right-side-rule mobile-order-1'>
                        <img style={{ width: "70%" }} src="/images/two.svg" alt="Правила" />
                    </div>
                </div>
                <div className='flex-container mobile-flex-direction '>
                    <div className='left-side-rule mobile-order-1'>
                        <img src="/images/three.svg" alt="Правила" />
                    </div>
                    <div className='right-side-rule rule-text mobile-order-2'>
                        <p>Игра длится до тех пор, пока одна из команд не наберёт заданное количество очков. Если несколько команд набрали одинаковое число очков, то в следующем раунде играют только они до тех пор, пока не определится победитель с наибольшим счётом.</p>
                    </div>
                </div>

            </div>
        </>
    );
}

export default RulesContent;