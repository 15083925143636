import React from "react";
import { Link } from "react-router-dom";
import AnimateBtn from "../components/AnimateBtn";
import RulesContent from "../components/Landing/RulesContent";

const Rules = () => {

  return (
    <>
      <div className="content-scroll">
        <h1 className="screen-header">Правила</h1>
        <RulesContent/>
      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn tomato" to="/settings">
              Назад
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default Rules;
