import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { setAlert } from "../actions/alertActions";
import { useSelector, useDispatch } from "react-redux";

function Check(props) {
    const dispatch = useDispatch();
    const teamsInfo = useSelector((state) => state.teams.teamsInfo);
    const products = useSelector((state) => state.productList.products);
    const gameWords = useSelector((state) => state.gameReducer.gameWords);
    const viewedLanding = useSelector((state) => state.settings.viewedLanding);
    let history = useHistory();

    useEffect(() => {
        const path = history.location.pathname
        const pathesForCheck = ["/presettings", "/score", "/game", "/results"]
        if (!viewedLanding) {
            history.push("/")
        }

        let isAnyProductSelected;
        /* console.log(gameWords.length, "gameWords.length") */

        if (products && products.length > 0 && gameWords.length === 0 && (path === "/game" || path === "/results")) {
            history.push("/score")
        }

        if (teamsInfo && teamsInfo.length < 2) {
            dispatch(setAlert("Число команд должно быть не менее двух", "danger"));
            history.push("/")
        }
        else if (products && products.length > 0) {
            isAnyProductSelected = products.find((product) => product.selected)
            if (!isAnyProductSelected && pathesForCheck.includes(path)) {
                dispatch(setAlert("Выберите хотя бы один набор слов", "danger"));
                history.push("/words")
            }
        }
        else if (products && products.length === 0 && pathesForCheck.includes(path)) {
            history.push("/")
        }
        else if (!products && pathesForCheck.includes(path)) {
           /*  console.log(products) */
            history.push("/")
        }
        else {
            /* dispatch(setAlert("Наборы слов были обновлены", "success")); */
            /* history.push("/words") */
        }



    }, [teamsInfo, dispatch, history, products, gameWords.length, viewedLanding])
    return (
        <>

        </>
    );
}

export default Check;

/* (path === "/presettings" || path === "/score" || path === "/game" || path === "/results") */