import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import AnimateBtn from "../components/AnimateBtn";
import SettingsBlock from "../components/SettingsBlock";

import { useDispatch } from "react-redux";

import { resetTeams } from "../actions/teamActions";
import { setQueue } from "../actions/queueActions";

const PreSettingsScreen = () => {
  const dispatch = useDispatch();

  const beforeGameHandler = () => {
    window.scrollTo(0, 0);
    dispatch(resetTeams())
    dispatch(setQueue(0))
  }

  useEffect(()=>{
    dispatch(resetTeams())
  },[dispatch])

  return (
    <>
      <div className="content-scroll">
      <h1 className="screen-header">Настройки</h1>
        <SettingsBlock />
      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn btn-secondary" to="/words">
              <i className="fas fa-arrow-left "></i>
            </Link>
          </AnimateBtn>
          <AnimateBtn callback={beforeGameHandler}>
            <Link className="btn next-btn" to="/score">
              Продолжить
            </Link>
          </AnimateBtn>

        </div>
      </div>
    </>
  );
};

export default PreSettingsScreen;
