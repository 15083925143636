import React, { useEffect } from "react";
import loadable from "@loadable/component";
import Loader from "./components/Loader";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";

import AlarmSoundComponent from "./components/AlarmSoundComponent";

import TeamScreen from "./screens/TeamScreen";
import CreateTeamScreen from "./screens/CreateTeamScreen"
import EditTeamScreen from "./screens/EditTeamScreen"
import Settings from "./screens/Settings"
import WordsScreen from "./screens/WordsScreen"
import ProductScreen from "./screens/ProductScreen"
import BuyAllScreen from "./screens/BuyAllScreen"
import CheckProductScreen from "./screens/CheckProductScreen"
import PreSettingsScreen from "./screens/PreSettingsScreen"
import ScoreScreen from "./screens/ScoreScreen";
import GameScreen from "./screens/GameScreen";
import RoundResultsScreen from "./screens/RoundResultsScreen"
import Rules from "./screens/Rules"
import OurApp from "./screens/OurApp"
import NotFound from "./screens/NotFound"

import Alert from "./components/Alert";
import Check from "./components/Check";
import LoginVK from "./screens/LoginVK";

/* import AdminSkeleton from "./Admin/AdminSkeleton";
import AdminSummary from "./Admin/AdminSummary";
import AdminUsers from "./Admin/AdminUsers";
import AdminProducts from "./Admin/AdminProducts";
import AdminEditProduct from "./Admin/AdminEditProduct";
import AdminTelegram from "./Admin/AdminTelegram"; */

const AdminSkeleton = loadable(() => import("./Admin/AdminSkeleton"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminSummary = loadable(() => import("./Admin/AdminSummary"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminUsers = loadable(() => import("./Admin/AdminUsers"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminProducts = loadable(() => import("./Admin/AdminProducts"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminEditProduct = loadable(() => import("./Admin/AdminEditProduct"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminTelegram = loadable(() => import("./Admin/AdminTelegram"), {
  delay: 100,
  fallback: <Loader center={true} />,
});
const AdminFindOrderByUserId = loadable(() => import("./Admin/AdminFindOrderByUserId"), {
  delay: 100,
  fallback: <Loader center={true} />,
});

const Wrapper = ({ children, alerts }) => {
  const viewedLanding = useSelector((state) => state.settings.viewedLanding);
  const history = useHistory()

  useEffect(() => {
    history.listen((newLocation, action) => {

      window.ym(88880646, "hit", history.location.pathname);
      window.gtag("send", "pageview", history.location.pathname);

      if (action === "PUSH") {
        if (
          newLocation.pathname !== history.currentPathname ||
          newLocation.search !== history.currentSearch
        ) {
          // Save new location
          history.currentPathname = newLocation.pathname;
          history.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }

      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }, [history])

  return (
    !viewedLanding ?
      <div>
        {children}
      </div> : <div style={{ maxWidth: "560px", margin: "0 auto" }}>
        {children}
      </div>
  )
}

const App = () => {
  const alerts = useSelector((state) => state.alertReducer);
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alias | Увлекательная игра для компании</title>
        <meta name="description" content="Популярная игра для компании. Надо объяснять друзьям слова, а им отгадывать. От 2 до 15 человек. Время игры от 15 минут. Никто не останется равнодушным" />
        <meta name="keywords" content="Alias, Алиас, настольная игра, игра для компании, игра на двоих, игра, игра для троих, настольная игра алиас, весёлая игра, игра для друзей, игра для подростков, тимбилдинг, смешная игра, отгадай слово, игра крокодил, игра для друзей, коллективная игра, игра в команде, алиас приложение" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <section>
        <Route path="/adminn/summary" exact>
          <AdminSkeleton>
            <AdminSummary />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/users/:pageNumber" exact>
          <AdminSkeleton>
            <AdminUsers />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/users/search/:keyword/:searchField/:pageNumber" exact>
          <AdminSkeleton>
            <AdminUsers />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/products" exact>
          <AdminSkeleton>
            <AdminProducts />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/telegram" exact>
          <AdminSkeleton>
            <AdminTelegram />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/product/edit/:id" exact>
          <AdminSkeleton>
            <AdminEditProduct />
          </AdminSkeleton>
        </Route>
        <Route path="/adminn/findUserOrders/:userId" exact>
          <AdminSkeleton>
            <AdminFindOrderByUserId />
          </AdminSkeleton>
        </Route>
      </section>

      <Wrapper>
        <main className="py-3">
          <div className="my-alert-wrapper">
            {alerts.length > 0 &&
              alerts.map((alert) => <Alert key={alert.id} alert={alert} />)
            }
          </div>
          <Check />
          <Route path="/" component={TeamScreen} exact />
          <Container>
            <Route path="/login_vk" component={LoginVK} exact />
            <Route path="/create-team" component={CreateTeamScreen} exact />
            <Route path="/edit-team/:id" component={EditTeamScreen} exact />
            <Route path="/settings" component={Settings} exact />
            <Route path="/words" component={WordsScreen} exact />
            <Route path="/product/:id" component={ProductScreen} exact />
            <Route path="/buyall" component={BuyAllScreen} exact />
            <Route path="/product/:id/check" component={CheckProductScreen} exact />
            <Route path="/presettings" component={PreSettingsScreen} exact />
            <Route path="/score" component={ScoreScreen} exact />
            <AlarmSoundComponent>
              <Route path="/game" component={GameScreen} exact />
              <Route path="/results" component={RoundResultsScreen} exact />
            </AlarmSoundComponent>
            <Route path="/rules" component={Rules} exact />
            <Route path="/app" component={OurApp} exact />
          </Container>
        </main>
      </Wrapper>

      {/* Ниже список всех роутов, чтобы показать 404 страницу */}
      <Switch>
        <Route path="/adminn/summary" exact />
        <Route path="/adminn/users/:pageNumber" exact />
        <Route path="/adminn/users/search/:keyword/:searchField/:pageNumber" exact />
        <Route path="/adminn/products" exact />
        <Route path="/adminn/telegram" exact />
        <Route path="/adminn/product/edit/:id" exact />
        <Route path="/adminn/findUserOrders/:userId" exact />
        <Route path="/" exact />
        <Route path="/login_vk" exact />
        <Route path="/create-team" exact />
        <Route path="/edit-team/:id" exact />
        <Route path="/settings" exct />
        <Route path="/words" exact />
        <Route path="/product/:id" exact />
        <Route path="/buyall" exact />
        <Route path="/product/:id/check" exact />
        <Route path="/presettings" exact />
        <Route path="/score" exact />
        <Route path="/game" exact />
        <Route path="/results" exact />
        <Route path="/rules" exact />
        <Route path="/app" exact />

        <Route path="*" component={NotFound} />
      </Switch>

    </Router>
  );
};

export default App;


