import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./TeamScreen.css";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import Helmet from "react-helmet";

import Landing from "../components/Landing/Landing";
import TeamComponent from "../components/teamComponent/TeamComponent";
import { editNameTeam } from "../actions/teamActions";
import { setAlert } from "../actions/alertActions";
import { mixMembers } from "../actions/teamActions";
import { setLanding } from "../actions/settingsActions";

import AnimateBtn from "../components/AnimateBtn";


const TeamScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [widthTeamEl, setWidthTeamEl] = useState();
  const teamsInfo = useSelector((state) => state.teams.teamsInfo);
  const viewedLanding = useSelector((state) => state.settings.viewedLanding);

  const ref = useRef(null);

  const viewedLandingHandler = () => {
    dispatch(setLanding(true))
  }

  useEffect(() => {
    setWidthTeamEl(ref.current ? ref.current.offsetWidth : 0);
    /* console.log("width", ref.current ? ref.current.offsetWidth : 0); */
    teamsInfo.map((team, idx) => {
      if (team.name === "" || !team.name) {
        dispatch(editNameTeam(`Команда ${idx + 1}`, team.id));
        return 0;
      } else return 0;
    });
  }, [dispatch, teamsInfo, viewedLanding]);


  const onBtnNextClick = () => {
    if (teamsInfo && teamsInfo.length < 2) {
      dispatch(setAlert("Число команд должно быть не менее двух", "danger"));
    }
  }

  const onCreateTeamClick = () => {
    window.scrollTo(0, 0);
    history.push("/create-team");
  }

  const onMixMembersClick = () => {
    const members = [];

    // собираем изо всех команд игроков в один массив
    teamsInfo.forEach(team => {
      if (team.members && team.members.length > 0) {
        team.members.forEach(memberObj => {
          members.push(memberObj)
        })
      }
    })

    if (members.length === 0) {
      dispatch(setAlert("Чтобы перемешать участников между командами, сначала необходимо добавить участников", "danger"))
    }
    else if (members.length < teamsInfo.length) {
      dispatch(setAlert("Число участников должно быть больше либо равно числу команд", "danger"))
    }
    else {
      dispatch(mixMembers(members))
    }
  }

  return (
    <>
      <Helmet>
        <title>Выбор команд | Alias-game</title>
      </Helmet>
      {
        !viewedLanding ? <Landing viewedLandingHandler={viewedLandingHandler} /> :
          <>
            <Container>
              <div className="content-scroll">
                <h1 className="screen-header">Выбор команд</h1>
                <div className="teams">
                  {teamsInfo.map((team, idx) => (
                    <TeamComponent
                      key={team.key}
                      name={team.name}
                      members={team.members}
                      color={team.color}
                      width={widthTeamEl}
                      avatar={team.avatar}
                      id={idx}
                    />
                  ))}
                  <AnimateBtn callback={onCreateTeamClick}>
                    <div style={{ textDecoration: "none", cursor:"pointer"}} className="team-container">
                      <div
                        ref={ref}
                        style={{ height: widthTeamEl }}
                        className={"team grey add-team"}
                      >
                        <i className="fas fa-plus plus"></i>
                      </div>
                      <p className="team-name text-center">Добавить команду</p>
                    </div>
                  </AnimateBtn>
                </div>
              </div>
              <div className="btn-section">
                <div className="btn-inner-container">
                  <AnimateBtn>
                    <Link className="btn btn-secondary" to="/settings">
                      <div>
                        <i className="fas fa-cog settings-icon"></i>
                      </div>
                    </Link>
                  </AnimateBtn>

                  {
                    teamsInfo && teamsInfo.length >= 2 ?
                      <AnimateBtn callback={()=>{window.scrollTo(0, 0);}}>
                        <Link className="btn next-btn" to="/words">
                          Продолжить
                        </Link>
                      </AnimateBtn>
                      :

                      <div onClick={onBtnNextClick} className="btn next-btn" >
                        Продолжить
                      </div>
                  }
                  <AnimateBtn callback={onMixMembersClick}>
                    <div className="btn btn-secondary next-btn">
                      <div>
                        <i className="fas fa-dice"></i>
                      </div>
                    </div>
                  </AnimateBtn>
                </div>
              </div>
            </Container>
          </>
      }
    </>
  );
};

export default TeamScreen;

/* <a
className="btn btn-primary"
href="https://oauth.vk.com/authorize?client_id=7883233&display=page&redirect_uri=http://localhost:3000/login_vk&scope=email&response_type=code&v=5.131"
>
Войти через вк
</a> */
