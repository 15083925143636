import React from 'react';
import Timer from '../Timer/Timer';
import { setIsPause, stopAudio } from '../../actions/gameActions';

import { useSelector, useDispatch } from "react-redux";

const GameHeader = (props) => {
    const dispatch = useDispatch();
    const isPause = useSelector((state) => state.gameReducer.isPause);

    const onPlayStopClick = () => {
        !isPause && dispatch(stopAudio())
        dispatch(setIsPause(!isPause))
    }

    return (
        <div className="game-header py-1" style={{ height: "61px" }}>
            {
                isPause ? <i className="fas fa-play play-stop" onClick={onPlayStopClick}></i> :
                    <i className="fas fa-pause play-stop" onClick={onPlayStopClick}></i>
            }

            <div></div>
            <Timer />
        </div>
    );
}

export default GameHeader;
