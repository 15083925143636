import React, { useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";

import { alarmSound, tiktokSound } from '../soundsBase64';
import { playAudio } from '../actions/gameActions';
function AlarmSoundComponent(props) {
    const dispatch = useDispatch();
    const isLastSeconds = useSelector((state) => state.gameReducer.isLastSeconds)
    const isTimeOver = useSelector((state) => state.gameReducer.isTimeOver)

    /* const [shouldPlay, setShouldPlay] = useState(true) */

    useEffect(() => {

        /* const playHandler = (condition, audio) => {
            if (condition && shouldPlay) {
                dispatch(playAudio(audio))
                setShouldPlay(false)
            }
            else if (!condition && !shouldPlay) {
                setShouldPlay(true)
            }
        }

        isLastSeconds && playHandler(isLastSeconds, "/sounds/tiktok.mp3");
        isTimeOver && playHandler(isTimeOver, "/sounds/alarm.mov"); */

        isLastSeconds && !isTimeOver && dispatch(playAudio(tiktokSound, "two"));
        isTimeOver &&  dispatch(playAudio(alarmSound));


    }, [isTimeOver, isLastSeconds, dispatch])

    return (
        <>
            {props.children}
        </>
    );
}

export default AlarmSoundComponent;


