import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  USER_PRODUCTS_REQUEST,
  USER_PRODUCTS_SUCCESS,
  USER_PRODUCTS_FAIL,
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  SELECT_PRODUCT,
  PRODUCT_LIST_LS,
  USER_PRODUCTS_LS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL
} from "../constants/productConstants";
import { logout } from "./userActions"
import { setAlert, removeAlert } from "./alertActions";
import { productCacheDuration } from "../configs/config"

import { shuffleArr } from "../utils/shuffleArr";


const shuffleEveryPack = (data) => {
  data.forEach(item => {
    if (item.words) {
      item.words = shuffleArr(item.words)
    }
  })
}

const transformLanguage = (language) => {
  if (language === "Русский") { return "russian" }
  else { return language.toLowerCase() }
}


export const getListProducts = (adminMode = false) => async (dispatch, getState) => {
  let productList = localStorage.getItem("productList")
    ? JSON.parse(localStorage.getItem("productList"))
    : null;

  if (adminMode) { productList = null; }

  if (productList) {
    dispatch({
      type: PRODUCT_LIST_LS,
      payload: productList.products,
    });
  }
  else {
    const {
      settings: { language },
    } = getState();

    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const config = {
        params: {
          lang: transformLanguage(language)
        }
      };

      const { data } = await axios.get(`/api/products`, config);
      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      });

      shuffleEveryPack(data);

      const objForSaveInLS = {
        products: data,
        expire: Date.now() + productCacheDuration
      }
      localStorage.setItem("productList", JSON.stringify(objForSaveInLS));

      if (!adminMode) { dispatch(setAlert("Наборы слов были обновлены", "success")) }
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};


export const getUserProducts = () => async (dispatch, getState) => {
  const userProducts = localStorage.getItem("userProducts")
    ? JSON.parse(localStorage.getItem("userProducts"))
    : null;

  if (userProducts) {
    dispatch({
      type: USER_PRODUCTS_LS,
      payload: userProducts.products,
    });
  }
  else {
    try {
      dispatch({ type: USER_PRODUCTS_REQUEST });
      const {
        userLogin: { userInfo },
        settings: { language },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          lang: transformLanguage(language)
        }
      };
/*       console.log(userInfo.token,"token") */
      if (userInfo.token) {
        const { data } = await axios.get(`/api/products/userProducts`, config);

        dispatch({
          type: USER_PRODUCTS_SUCCESS,
          payload: data,
        });

        shuffleEveryPack(data);

        const objForSaveInLS = {
          products: data,
          expire: Date.now() + productCacheDuration
        }
        localStorage.setItem("userProducts", JSON.stringify(objForSaveInLS));

        dispatch(setAlert("Наборы слов были обновлены", "success"))
      }
    } catch (error) {
      dispatch({
        type: USER_PRODUCTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      error.response && error.response.data.message && error.response.data.message === "Not authorized, token failed. Попробуйте повторно авторизироваться в игре" && dispatch(logout()); dispatch(setAlert(error.response.data.message, "danger"))
    }
  }
};


export const getAdminProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_PRODUCTS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/products/adminProducts`, config)

    dispatch({
      type: ADMIN_PRODUCTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }

};


export const selectProduct = (id) => (dispatch, getState) => {
  const {
    productList: { products },
    userLogin: { userInfo },
    alertReducer
  } = getState();

  const newData = products.map((el) => {
    if (el._id === id) {
      return Object.assign({}, el, { selected: !el.selected });
    }
    return el;
  });
  dispatch({ type: SELECT_PRODUCT, payload: newData });


  if (userInfo && localStorage.getItem("userProducts")) {
    const objForSaveInLS = {
      products: newData,
      expire: JSON.parse(localStorage.getItem("userProducts")).expire
    }
    localStorage.setItem("userProducts", JSON.stringify(objForSaveInLS))
  }
  else {
    const objForSaveInLS = {
      products: newData,
      expire: JSON.parse(localStorage.getItem("productList")).expire
    }
    localStorage.setItem("productList", JSON.stringify(objForSaveInLS))
  }

  //убираем алерт если он есть при выборе набора слов
  alertReducer && alertReducer.length > 0 && alertReducer.forEach(alert => {
    if (alert.msg === "Выберите хотя бы один набор слов") {
      dispatch(removeAlert(alert.id))
    }
  });
};

export const getProductById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/products`, {}, config)

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config
    )

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload: message,
    })
  }
}
