import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../components/Tabs/Tabs";
import {
    setRoundDuration,
    setScoreToWin,
    setMinusScore,
} from "../actions/settingsActions";

import Switch from 'react-ios-switch'

function SettingsBlock(props) {
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    return (
        <div style={{marginBottom:"1rem"}}>
            <h6 className="text-center">Длительность раунда (с)</h6>
            <Tabs
                clickHandler={(value) => {
                    dispatch(setRoundDuration(value));
                }}
                data={[30, 60, 90]}
                active={settings.roundDuration}
            />

            <h6 className="text-center">Очки для победы</h6>
            <Tabs
                clickHandler={(value) => {
                    dispatch(setScoreToWin(value));
                }}
                data={[10, 25, 50, 75, 100]}
                active={settings.scoreToWin}
            />
            <div className="flexbox-space-between flexbox-align-items">
                <h6 style={{ marginBottom: "0" }}>Отнимать очки за пропуск</h6>

                <Switch checked={settings.minusScore}
                    onChange={() => dispatch(setMinusScore(!settings.minusScore))} />
            </div>
        </div>
    );
}

export default SettingsBlock;