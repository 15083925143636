import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from '../constants/alertConstants';

const findAlertsByMsg = (alerts, msg) => {
  return alerts.filter(alert=> alert.msg === msg)
  }

export const setAlert = (msg, alertType, timeout = 5000, shouldStack = false) => (dispatch, getState) => {
  const {
    alertReducer,
  } = getState();

  const id = uuidv4();
  if (!shouldStack) {
    const findedAlerts = findAlertsByMsg(alertReducer, msg);
    findedAlerts && findedAlerts.length > 0 &&
    findedAlerts.forEach(alert => {
      dispatch(removeAlert(alert.id))
    });
  }
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id, timeout }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const removeAlert = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ALERT, payload: id });
};


