import { SET_QUEUE } from '../constants/queueConstants';

export const setQueue = (position) => (dispatch) => {
  dispatch({
    type: SET_QUEUE,
    payload: position
  });
};




