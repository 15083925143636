import {
  USER_LOGIN_VK_FAIL,
  USER_LOGIN_VK_REQUEST,
  USER_LOGIN_VK_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_DATA_LOCALY,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_TOOGLE_PRODUCT_REQUEST,
  USER_TOOGLE_PRODUCT_SUCCESS,
  USER_TOOGLE_PRODUCT_FAIL,
  USERS_SUMMARY_REQUEST,
  USERS_SUMMARY_SUCCESS,
  USERS_SUMMARY_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_VK_REQUEST:
      return { loading: true };
    case USER_LOGIN_VK_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_VK_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case USER_UPDATE_DATA_LOCALY:
      return { userInfo: action.payload };
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count
      }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userToogleProductReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TOOGLE_PRODUCT_REQUEST:
      return { loading: true }
    case USER_TOOGLE_PRODUCT_SUCCESS:
      return { loading: false, success: true }
    case USER_TOOGLE_PRODUCT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userSummary = (state = {}, action) => {
  switch (action.type) {
    case USERS_SUMMARY_REQUEST:
      return { loading: true };
    case USERS_SUMMARY_SUCCESS:
      return { loading: false, userSummary: action.payload };
    case USERS_SUMMARY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
