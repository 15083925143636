import React from "react";
import "./Product.css";
import useCountAllProductsDiscount from "../../hooks/useCountAllProductsDiscount";

import AnimateBtn from "../AnimateBtn";

function BuyAll({ products, history }) {
    const { totalPrice, totalPriceDiscount } = useCountAllProductsDiscount(products, 10)

    const productStyle = {
        backgroundImage: `url(/images/products/buyall.jpeg)`,
        backgroundSize: "cover",
    };

    const onClick = () => {
        window.scrollTo(0, 0);
        history.push(`/buyall`)
    };
    
    return (
        <>{totalPrice > 0 &&
            <div>
                <AnimateBtn callback={onClick}>
                    <div
                        className="product"
                        style={productStyle}
                    >
                        <h6>Все наборы</h6>
                        <p className="product-price">Купить за <span style={{ textDecoration: "line-through" }}>{totalPrice}</span> ₽ <br />
                            <span style={{ fontSize: "1rem" }}>{totalPriceDiscount} ₽</span>
                        </p>
                    </div>
                </AnimateBtn>
            </div>
        }
        </>
    );
}

export default BuyAll;
