import {
  SET_ROUND_DURATION,
  SET_SCORE_TO_WIN,
  SET_MINUS_SCORE,
  SET_LANGUAGE,
  SET_LANDING
} from "../constants/settingsConstants";
import { PRODUCTS_RESET } from "../constants/productConstants";
import { delProductsCache } from "../utils/cache";

function getLSSettingsInfo() {
  if (localStorage.getItem("settings")) {
    let lsItems = JSON.parse(localStorage.getItem("settings"));
    if (lsItems.length !== 0) {
      return lsItems;
    } else {
      return {};
    }
  }
}
let currentItems;

export const setRoundDuration = (value) => (dispatch) => {
  currentItems = getLSSettingsInfo();
  currentItems.roundDuration = value;

  dispatch({
    type: SET_ROUND_DURATION,
    payload: currentItems.roundDuration,
  });

  localStorage.setItem("settings", JSON.stringify(currentItems));
};

export const setScoreToWin = (value) => (dispatch) => {
  currentItems = getLSSettingsInfo();
  currentItems.scoreToWin = value;

  dispatch({
    type: SET_SCORE_TO_WIN,
    payload: currentItems.scoreToWin,
  });

  localStorage.setItem("settings", JSON.stringify(currentItems));
};

export const setLanguage = (value) => (dispatch) => {
  currentItems = getLSSettingsInfo();
  currentItems.language = value;

  dispatch({
    type: SET_LANGUAGE,
    payload: currentItems.language,
  });
  delProductsCache();
  dispatch({ type: PRODUCTS_RESET })

  localStorage.setItem("settings", JSON.stringify(currentItems));
};

export const setLanding = (value) => (dispatch) => {
  currentItems = getLSSettingsInfo();
  currentItems.viewedLanding = value;

  dispatch({
    type: SET_LANDING,
    payload: currentItems.viewedLanding,
  });
  delProductsCache();

  localStorage.setItem("settings", JSON.stringify(currentItems));
};

export const setMinusScore = (value) => (dispatch) => {
  currentItems = getLSSettingsInfo();
  currentItems.minusScore = value;
  dispatch({
    type: SET_MINUS_SCORE,
    payload: currentItems.minusScore,
  });

  localStorage.setItem("settings", JSON.stringify(currentItems));
};