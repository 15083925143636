import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AnimateBtn from "../components/AnimateBtn";
import TinderCards from "../components/TinderCards/TinderCards"
import GameHeader from "../components/GameHeader/GameHeader";

import { useSelector, useDispatch } from "react-redux";
import { setIsPause } from "../actions/gameActions";
import { resetAlreadyWords, resetGameWords } from "../actions/gameActions";


const GameScreen = ({ history }) => {

  const dispatch = useDispatch();
  const isPause = useSelector((state) => state.gameReducer.isPause);
  const [btnSwipeDir, setBtnSwipeDir] = useState(null)
  const swipeBtnClickHandler = (dir) => {
    setBtnSwipeDir(dir)
  }

  const onResumeClick = () => {
    dispatch(setIsPause(false))
  }

  const onExitClick = () => {
    dispatch(setIsPause(false))
    dispatch(resetGameWords())
    dispatch(resetAlreadyWords())
  }

  useEffect(() => {
    const onVisibilitychange = () => {
      if (document.visibilityState !== 'visible') {
        dispatch(setIsPause(true))
      }
    }
    document.addEventListener("visibilitychange", onVisibilitychange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilitychange);
    };
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="game-body">
      <div className="content-scroll">
        <GameHeader />

        <div className={!isPause ? "display-none" : "pause-menu"}>
          <h4 style={{ color: "white" }}>Пауза</h4>
          <AnimateBtn callback={onResumeClick}>
            <div className="btn" style={{ marginLeft: "0" }} >
              Продолжить
            </div>
          </AnimateBtn>
          <AnimateBtn callback={onExitClick}>
            <Link className="btn tomato" style={{ marginTop: "2rem", marginLeft: "0" }} to="/">
              Выйти из игры
            </Link>
          </AnimateBtn>
        </div>

        <TinderCards
          btnSwipeDir={btnSwipeDir}
          swipeBtnClickHandler={swipeBtnClickHandler}
          history={history}
        />
      </div>

      {!isPause &&
        <div className="btn-section" style={{ background: "none" }}>
          <div className="btn-inner-container">
            <div
              onClick={() => swipeBtnClickHandler("left")}
              className="yes-no-btn"
            >
              <AnimateBtn>
                <div className="btn tomato" style={{ marginLeft: "0" }} >
                  нет
                </div>
              </AnimateBtn>
            </div>
            <div
              onClick={() => swipeBtnClickHandler("right")}
              className="yes-no-btn pl10"
            >
              <AnimateBtn>
                <div className="btn"  >
                  Да
                </div>
              </AnimateBtn>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default GameScreen;
