import React from 'react';
import privacyPolicy from "./PrivacyPolicy.pdf"
import terms from "./TermsAndConditions.pdf"

function PolicyDisclaimer(props) {
    return (
        <div className='text-center'>
            <p>Заходя на сайт через ВК, вы соглашаетесь с
                <a href={privacyPolicy} className="blue-text" target="_blank" rel="noopener noreferrer"> Политикой конфиденциальности </a>
                и принимаете
                <a href={terms} className="blue-text" target="_blank" rel="noopener noreferrer"> Условия пользовательского соглашения</a></p>
        </div>
    );
}

export default PolicyDisclaimer;