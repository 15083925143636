import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";

function AnimateBtn({ children, callback, preventUrlChange = false }) {
    
    let history = useHistory();
    const [classes, setClasses] = useState(children.props.className)
    const instance = useRef({ timer: 0 });

    const btnAnimation = (event) => {
        event.preventDefault();
        clearTimeout(instance.current.timer);
        setClasses(classes + " btn-animation")
        instance.current.timer = setTimeout(() => {
            setClasses(children.props.className)
            if (!preventUrlChange) {
                if (children.props.to) {
                    history.push(children.props.to)
                }
            }
            if (callback) { callback(event) }
        }, 300)
    }

    useEffect(() => {
        const needToClean = instance.current
        return () => {
            clearTimeout(needToClean.timer);
        };
    }, []);

    /* console.log(children.props.to) */
    return (
        <>
            {React.cloneElement(children, {
                onClick: btnAnimation,
                className: classes
            })
            }
        </>
    )
}

export default AnimateBtn;

