import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendVKCodeToServer } from "../actions/userActions";
import { getUserProducts } from "../actions/productActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const LoginVK = ({ location, history }) => {
  const dispatch = useDispatch();

  const [otherErr, setOtherErr] = useState()

  const userLogin = useSelector((state) => state.userLogin);
  const { loading: userLoading, error: userError } = userLogin;

  const productsList = useSelector((state) => state.productList);
  const { loading: productLoading, error: productError } = productsList;

  useEffect(() => {
    /* console.log(location.search); */
    const vkCode = location.search.split("=")[1];

    if (location.search.split("=")[0] !== "?error") {
      (async function () {
        await dispatch(sendVKCodeToServer(vkCode));
        await dispatch(getUserProducts());
        const redirect = JSON.parse(localStorage.getItem("vkRedirect"))
        history.push(redirect);
        localStorage.removeItem("vkRedirect");

      })();
    }
    else {
      if (location.search.split("&")[1] === 'error_reason=user_denied') {
        history.push("/");
        /* setOtherErr("Вы отказались от входа в игру. Попробуйте ещё раз.") */
      }
      else {setOtherErr("Ошибка входа")}

      /* console.log(location.search.split("&")[1]); */
    }

  }, [location.search, dispatch, history]);

  return (
    <>
      {productLoading || userLoading ? (
        <Loader />
      ) : userError || productError || otherErr ? (
        <>
          <div className="py-3" style={{ display: "flex", justifyContent: "center" }}>
            <img className='alias-logo' style={{ width: "200px" }} src='images/alias_logo.png' alt="logo mini" />
          </div>
          {otherErr && <Message variant="danger">{otherErr}</Message>}
          {userError && <Message variant="danger">{userError}</Message>}
          {productError && <Message variant="danger">{productError}</Message>}

          <a className="btn btn-primary" href="/">
            Главная страница
          </a>
        </>
      ) : (
        <>
          <h1>Login vk</h1>
          <a className="btn btn-primary" href="/">
            Главная страница
          </a>
        </>
      )}
    </>
  );
};

export default LoginVK;
