import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";

import { getListProducts, getUserProducts } from "../actions/productActions";
import { setAlert } from "../actions/alertActions";
import { checkYooOrder, checkYooOrderBuyAll } from "../actions/purchaseActions";

import Product from "../components/Product/Product";
import BuyAll from "../components/Product/BuyAll";
import Loader from "../components/Loader";
import Message from "../components/Message";
import AnimateBtn from "../components/AnimateBtn";

function compareByWords(a, b) {
  if (a.words && !b.words) {
    return -1;
  }
  else {
    return 1;
  }
}

const WordsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const productsList = useSelector((state) => state.productList);
  const { loading, error, products } = productsList;

  if (products && products.length > 0) {
    Array.from(products).sort(function (x, y) {
      return (x.isFree === y.isFree) ? 0 : x.isFree ? -1 : 1;
    });
    Array.from(products).sort(compareByWords);
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const teamsInfo = useSelector((state) => state.teams.teamsInfo);

  useEffect(() => {
    const productForPaymentCheck = localStorage.getItem("productForPaymentCheck")
      ? JSON.parse(localStorage.getItem("productForPaymentCheck"))
      : null;

    if (productForPaymentCheck) {
      if (productForPaymentCheck === "buyAll") {
        dispatch(checkYooOrderBuyAll(history));
      }
      else {
        dispatch(checkYooOrder(productForPaymentCheck, history));
      }
    }

    if (teamsInfo && teamsInfo.length < 2) {
      dispatch(setAlert("Число команд должно быть не менее двух", "danger"));
      history.push("/")
    }
    else {
      if (userInfo && userInfo.hasPaidProducts) {
        dispatch(getUserProducts());
      } else {
        dispatch(getListProducts());
      }
    }
  }, [dispatch, userInfo, history, teamsInfo]);

  const nextStepCheck = () => {
    let isAnyProductSelected;
    if (products && products.length > 0) {
      isAnyProductSelected = products.find((product) => product.selected)
      if (products && products.length > 0 && !isAnyProductSelected) {
        dispatch(setAlert("Выберите хотя бы один набор слов", "danger"));
        history.push("/words")
      }
      else {history.push("/presettings")}
    }
    else if (!products) {
      dispatch(setAlert("Нельзя продолжить без наборов слов", "danger"));
      history.push("/words")
    }
    
  }

  return (
    <>
      <Helmet>
        <title>Выберите наборы слов | Alias-game</title>
      </Helmet>

      <div className="content-scroll">
        <h1 className="screen-header">Выберите наборы</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <>
            <Message variant="danger">{error}</Message>
            <a className="btn" href="/words">
              Обновить
            </a>
          </>
        ) : (
          <div className="teams">
            {products && products.map((product) => (
              <Product
                key={product._id}
                id={product._id}
                name={product.name}
                price={product.price}
                image={product.image}
                isAvailable={product.words ? true : false}
                selected={product.selected}
                history={history}
              />
            ))}
           {products && <BuyAll
              products={products}
              history={history}
            />}
          </div>
        )}
      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn btn-secondary" to="/">
              <i className="fas fa-arrow-left "></i>
            </Link>
          </AnimateBtn>
          <AnimateBtn callback={nextStepCheck}>
            <div className="btn next-btn" >
              Продолжить
            </div>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default WordsScreen;
