import React from "react";
import { Link } from "react-router-dom";
import AnimateBtn from "./AnimateBtn";

function Confirm(props) {

  return (
    <>
      <div className="btn-section">
        <h5>{props.children}</h5>
        <div className="btn-inner-container">
          <AnimateBtn >
            <Link className="btn tomato" to="/presettings">
              Выйти
            </Link>
          </AnimateBtn>

          <AnimateBtn callback={props.onPlayClick}>
            <Link className="btn next-btn" to="/game">
              Играть
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
}

export default Confirm;
