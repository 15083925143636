import {
  YOO_ORDER_REQUEST,
  YOO_ORDER_SUCCESS,
  YOO_ORDER_FAIL,
  YOO_ORDER_CHECK_REQUEST,
  YOO_ORDER_CHECK_SUCCESS,
  YOO_ORDER_CHECK_FAIL,
  YOO_ORDER_CHECK_BUYALL_REQUEST,
  YOO_ORDER_CHECK_BUYALL_SUCCESS,
  YOO_ORDER_CHECK_BUYALL_FAIL
} from "../constants/purchaseConstants";

export const purchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case YOO_ORDER_REQUEST:
      return { loading: true };
    case YOO_ORDER_SUCCESS:
      return { loading: false, yooInfo: action.payload };
    case YOO_ORDER_FAIL:
      return { loading: false, error: action.payload };

    case YOO_ORDER_CHECK_REQUEST:
      return { loading: true };
    case YOO_ORDER_CHECK_SUCCESS:
      return { loading: false, yooInfo: action.payload };
    case YOO_ORDER_CHECK_FAIL:
      return { loading: false, error: action.payload };

      case YOO_ORDER_CHECK_BUYALL_REQUEST:
        return { loading: true };
      case YOO_ORDER_CHECK_BUYALL_SUCCESS:
        return { loading: false, yooInfo: action.payload };
      case YOO_ORDER_CHECK_BUYALL_FAIL:
        return { loading: false, error: action.payload };

    default:
      return state;
  }
};


