import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/userActions";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import Tabs from "../components/Tabs/Tabs";
import VkBtn from "../components/VkBtn";
import AnimateBtn from "../components/AnimateBtn";
import SettingsBlock from "../components/SettingsBlock";
import PolicyDisclaimer from "../policy/PolicyDisclaimer";
import PolicyLinks from "../policy/PolicyLinks";
import { setLanding, setLanguage } from "../actions/settingsActions";


const Settings = ({ history }) => {
  const settings = useSelector((state) => state.settings);
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const dispatch = useDispatch();
  let firstName = "";
  let lastName = "";
  let avatar = null;
  if (userInfo) {
    firstName = userInfo.first_name ? userInfo.first_name : ""
    lastName = userInfo.last_name ? userInfo.last_name : ""
    avatar = userInfo.avatar ? userInfo.avatar : null
  }
  const logoutHandler = () => {
    dispatch(logout())
  }

  const onLendingClick = () => {
    window.scroll(0, 0);
    dispatch(setLanding(false))
    history.push("/");
  }

  const onAppLinkClick = () => {window.scroll(0,0) }

  return (
    <>
      <Helmet>  
        <title>Настройки | Alias-game</title>
      </Helmet>
      <div className="content-scroll">
        <h1 className="screen-header">Настройки</h1>
        <h6 className="text-center">Язык наборов слов</h6>
        <Tabs
          clickHandler={(value) => {
            dispatch(setLanguage(value));
          }}
          data={["Русский", "English"]}
          active={settings.language}
        />
        <SettingsBlock />
        {!userInfo && <>
          <PolicyDisclaimer />
          <VkBtn />
        </>}
        {userInfo &&
          <div className="profile-card">
            {avatar && <img className="avatar" src={avatar} alt="user profile"></img>}
            <p className="profile-p text-center m-0">{firstName} {lastName}</p>
            <div onClick={logoutHandler} className="logout-btn"> Выйти </div>
          </div>
        }
        <AnimateBtn>
          <Link className="btn" to="/rules">
            Правила игры
          </Link>
        </AnimateBtn>
        {
          userInfo && userInfo.isAdmin && (<AnimateBtn>
            <Link className="btn" to="/adminn/users/1">
              Админ
            </Link>
          </AnimateBtn>)
        }

        {userInfo &&
          <div className="mt-5">
            <h6>О нас</h6>
            <PolicyLinks />
            <a href="https://vk.com/alias__game" className="blue-text" target="_blank" rel="noopener noreferrer"> Наша группа в ВК</a>
          </div>}
        {!userInfo &&
          <div className="mt-5">
            <h6>О нас</h6>
            <p>
              <a href="https://vk.com/alias__game" className="blue-text" target="_blank" rel="noopener noreferrer"> Наша группа в ВК</a>
            </p>
          </div>}

        <div className="my-1">
          <div className="blue-text" onClick={onLendingClick}>Лендинг</div>
        </div>

        <Link onClick={onAppLinkClick} className="blue-text" to="/app">
          Приложение <i className="fab fa-android app-mini-icon-margin android-color"></i> <i className="fab fa-apple app-mini-icon-margin"></i>
        </Link>


      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn callback={()=>{window.scrollTo(0, 0);}}>
            <Link className="btn btn-secondary-color" to="/">
              На главную
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default Settings;
