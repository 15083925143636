import React, { useState, useEffect } from 'react';

function Word({ index, topCard, string, cardWidth }) {

    const [arrayOfWords, setArrayOfWords] = useState([]);
    const [adaptiveFontSize, setAdaptiveFontSize] = useState();
    useEffect(() => {
        const addBr = (str) => {
            let arr;
            const resultArr = [];
            arr = str.split(' ');
            const arrLength = arr.length;
            arr.forEach((item, i) => {
                resultArr.push(item);
                i + 1 !== arrLength && resultArr.push(<br />);
            });
            return resultArr;
        }
        const findMaxWordLength = (str) => {
            let max = 0;
            let arr;
            arr = str.split(' ');
            arr.forEach(word => {
                if (word.length > max) max = word.length
            })
            return max
        }

        const maxWordLength = findMaxWordLength(string);
        setArrayOfWords(addBr(string))

        if (cardWidth !== 0) {
            if (!adaptiveFontSize) { setAdaptiveFontSize("1.7rem") }
            if (cardWidth <= 240) { //galaxy fold
                if (maxWordLength === 10) {
                    setAdaptiveFontSize("1.55rem")
                }
                if (maxWordLength > 10 && maxWordLength < 14) {
                    setAdaptiveFontSize("1.2rem")
                }
                if (maxWordLength >= 14 && maxWordLength <= 16) {
                    setAdaptiveFontSize("1rem")
                }
                if (maxWordLength >= 17 && maxWordLength < 19) {
                    setAdaptiveFontSize("0.84rem")
                }
                if (maxWordLength >= 19 && maxWordLength <= 20) {
                    setAdaptiveFontSize("0.74rem")
                }
                if (maxWordLength > 20) {
                    setAdaptiveFontSize("0.7rem")
                }
            }
            else if (cardWidth <= 310) { //galaxy s8+
                if (maxWordLength >= 14 && maxWordLength <= 16) {
                    setAdaptiveFontSize("1.34rem")
                }
                if (maxWordLength >= 17 && maxWordLength < 20) {
                    setAdaptiveFontSize("1.1rem")
                }
                if (maxWordLength === 20) {
                    setAdaptiveFontSize("1rem")
                }
                if (maxWordLength > 20) {
                    setAdaptiveFontSize("0.9rem")
                }
            }
            else if (cardWidth <= 335) { //iphone se (320) 12pro (332)
                if (maxWordLength >= 14 && maxWordLength <= 16) {
                    setAdaptiveFontSize("1.45rem")
                }
                if (maxWordLength >= 17 && maxWordLength < 20) {
                    setAdaptiveFontSize("1.15rem")
                }
                if (maxWordLength >= 20) {
                    setAdaptiveFontSize("1rem")
                }
            }
            else if (cardWidth <= 352) { //iphone XR
                if (maxWordLength >= 14 && maxWordLength <= 16) {
                    setAdaptiveFontSize("1.6rem")
                }
                if (maxWordLength >= 17 && maxWordLength < 19) {
                    setAdaptiveFontSize("1.25rem")
                }
                if (maxWordLength >= 19) {
                    setAdaptiveFontSize("1.20rem")
                }
                if (maxWordLength >= 21) {
                    setAdaptiveFontSize("1rem")
                }
            }
            else if (cardWidth <= 460) { //surface duo
                if (maxWordLength >= 14 && maxWordLength <= 16) {
                    setAdaptiveFontSize("1.6rem")
                }
                if (maxWordLength >= 17 && maxWordLength < 19) {
                    setAdaptiveFontSize("1.4rem")
                }
                if (maxWordLength >= 19) {
                    setAdaptiveFontSize("1.3rem")
                }
                if (maxWordLength >= 21) {
                    setAdaptiveFontSize("1.2rem")
                }
            }
            else { setAdaptiveFontSize("1.7rem") }
        }
    }, [cardWidth, adaptiveFontSize, string])

    return (
        <> {
            (cardWidth > 0 && adaptiveFontSize) ?
                <h3
                    style={{
                        whiteSpace: "pre-line",
                        fontSize: adaptiveFontSize,
                        width: "83vw",
                        maxWidth: "600px",
                        lineHeight: "1.6",
                        marginBottom:"0px",
                        paddingBottom: "10px"
                    }}
                    className={index === topCard ? 'top-card-unblur card-blur'
                        : 'card-blur'}
                >
                    {arrayOfWords.map((word, index) => <span key={index}>{word}</span>)}
                </h3> : <></>}
        </>

    );
}

export default Word;




