import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AnimateBtn from "../components/AnimateBtn";
import ScoreScreenHeader from "../components/ScoreScreenHeader";
import Confirm from "../components/Confirm";

import { generateGameWords } from "../actions/gameActions";
import { resetAlreadyWords } from "../actions/gameActions";
import { setIsTimeOver, setIsLastSeconds } from "../actions/gameActions";
import { resetTeams } from "../actions/teamActions";
import { setQueue } from "../actions/queueActions";
import { setInitialAudio, setInitialAudioСhannelTwo } from "../actions/gameActions";

import "../screens/TeamScreen.css"


const FireWorks = () => {
  return (
    <div className="pyro">
      <div className="before"></div>
      <div className="after"></div>
    </div>
  )
}

const ScoreScreen = ({ history }) => {
  const dispatch = useDispatch();
  const teamsInfo = useSelector((state) => state.teams.teamsInfo);
  const currentQueuePos = useSelector(state => state.queue)
  const scoreToWin = useSelector(state => state.settings.scoreToWin)
  const selectedProducts = useSelector((state) =>
    state.productList.products.filter((product) => product.selected === true)
  );
  const [winner, setWinner] = useState(null);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const isPlayingTeams = teamsInfo.filter(team => team.isPlaying)
    if (isPlayingTeams.length === 1) {
      setWinner(isPlayingTeams[0])
    }
    dispatch(resetAlreadyWords())
    dispatch(setIsTimeOver(false))
    dispatch(setIsLastSeconds(false))
  }, [currentQueuePos, teamsInfo, dispatch, scoreToWin])

  const onPlayClick = () => {
    dispatch(generateGameWords(selectedProducts, 2))
    dispatch(setInitialAudio())
    dispatch(setInitialAudioСhannelTwo())
  }

  const onReplayClick = () => {
    dispatch(resetTeams())
    dispatch(setQueue(0))
    setWinner(null)
  }

  const onBackClickHandler = () => {
    const anyScore = teamsInfo.filter(team => team.score > 0)
    if (currentQueuePos === 0 && anyScore.length === 0) {
      history.push("/presettings")
    }
    else if (winner) {
      setWinner(null)
      history.push("/presettings")
    }
    else { setShowWarning(true); console.log("Игра идёт, точно вернуться назад?") }
  }

  return (
    <>
      <div className="content-scroll">
        {
          winner ?
            <ScoreScreenHeader
              header="Победила команда"
              color={winner.color}
              avatar={winner.avatar}
              name={winner.name}
            >
              <FireWorks />
            </ScoreScreenHeader>
            :
            <ScoreScreenHeader
              header="Очередь команды"
              color={teamsInfo[currentQueuePos].color}
              avatar={teamsInfo[currentQueuePos].avatar}
              name={teamsInfo[currentQueuePos].name}
              animationClass="simple-animation"
            ></ScoreScreenHeader>
        }

        {teamsInfo.map((team, index) => (
          <div key={team.key} className={"list-item list-item-team  " + (index === currentQueuePos && "current-team")} >
            <div className="flex-container">
              <div className={`avatar-list-item ${team.color}`}>
                <img className="avatar-img" src={team.avatar} alt="avatar" />
              </div>
              <div >
                <h6 className={"list-item-name text-left members-names-score-screen "}>{team.name}</h6>
                {
                  team.members && team.members.length > 0 &&
                  <p className="members-names-score-screen ">
                    {
                      team.members.map((member, idx) =>
                        <span key={member.key} className={"list-item-name " + (team.memberQueue === idx && index === currentQueuePos && !winner ? "tomato-text" : "")}> {member.name}
                        </span>)
                    }
                  </p>
                }
              </div>
            </div>
            <p className={"score " + (index === currentQueuePos && "current-score")}>{team.score}</p>
          </div>
        ))}
      </div>

      <div className="btn-section">
        <div className="btn-inner-container">
          {
            showWarning ?
              <Confirm onPlayClick={onPlayClick}>
                Точно выйти?
              </Confirm> :
              <>
                <AnimateBtn callback={onBackClickHandler} preventUrlChange={true}>
                  <Link className="btn btn-secondary" to="/presettings">
                    <i className="fas fa-arrow-left "></i>
                  </Link>
                </AnimateBtn>
                {
                  winner ?
                    <AnimateBtn callback={onReplayClick}>
                      <Link className="btn next-btn" to="/score">
                        Играть снова
                      </Link>
                    </AnimateBtn>
                    :
                    <AnimateBtn callback={onPlayClick}>
                      <Link className="btn next-btn" to="/game">
                        Играть
                      </Link>
                    </AnimateBtn>
                }
              </>
          }
        </div>
      </div>
    </>
  );
};

export default ScoreScreen;



/*  <>
             <h2 style={{ paddingBottom: "0" }} className="text-center">Победила команда</h2>

             <div class="pyro">
               <div class="before"></div>
               <div class="after"></div>
             </div>

             <div style={{ margin: "1.2rem 0" }}>
               <div className={`avatar-list-item ${winner.color} current-queue-avatar`}>
                 <img className="avatar-img " src={winner.avatar} alt="avatar" />
               </div>
               <h5 style={{ margin: "0" }} className="text-center">{winner.name} </h5>
             </div>
           </> */



/*       <>
      <h2 style={{ paddingBottom: "0" }} className="text-center">Очередь команды </h2>
      <div style={{ margin: "1.2rem 0" }}>
        <div className={`avatar-list-item ${teamsInfo[currentQueuePos].color} current-queue-avatar simple-animation`}>
          <img className="avatar-img" src={teamsInfo[currentQueuePos].avatar} alt="avatar" />
        </div>
        <h5 style={{ margin: "0" }} className="text-center">{teamsInfo[currentQueuePos].name} </h5>
      </div>
    </> */