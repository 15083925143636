import React from 'react';
import { vkOauthUrl } from '../configs/config';

import { useHistory } from "react-router-dom";

function VkBtn(props) {
    let history = useHistory();
    const splittedUrl = history.location.pathname.split("/");
    let vkRedirect
    switch (splittedUrl[1]) {
        case "settings":
            vkRedirect = "/settings"
            break;
        default:
            vkRedirect = "/words"
            break;
    }
    /* console.log(splittedUrl[1]) */
    const onBtnClick = () => {
        localStorage.setItem("vkRedirect", JSON.stringify(`${vkRedirect}`))
    }
    return (
        <a
            onClick={onBtnClick}
            className="btn"
            href={vkOauthUrl}
        >
            Войти через вк
        </a>
    );
}

export default VkBtn;