import React from 'react';
import "./ProductCard.css";
import "../Product/Product.css"

function AllProductCard({ img, name, price, discountPercent, discountPrice, length, productsNames }) {
    return (
        <div className='my-card'>
            <div className='my-card-img-container'>
                <img className='my-card-img' src={img} alt={name} />
            </div>
            <div className='my-card-info'>
                <h5>{name}</h5>
                <p style={{ fontSize: "1rem" }}>
                    Количество слов: <span style={{ fontWeight: "600" }}>{length}</span>  <br />
                    Цена: <span style={{ fontWeight: "600", textDecoration: "line-through" }}>{price} ₽</span><br />
                    Скидка: <span style={{ fontWeight: "600" }}>{discountPercent} %</span><br />
                    Итоговая цена: <span style={{ fontWeight: "600" }}>{discountPrice} ₽</span>
                </p>
                <div className='card-gift'>
                    <div><img style={{ width: "50px", marginRight: "10px" }} src="/images/gift.png" alt="gift" /></div>
                    <p style={{ margin: "0px" }}>Слова из этих наборов на других языках в подарок. Выбрать язык можно в настройках игры.</p>
                </div>
                <p style={{ fontSize: "1rem" }}>Наборы:</p>
                {
                    productsNames && productsNames.map((word, index) => (
                       word ? <p key={index} className='chips' style={{ border: "1px solid gold" }}>{word}</p> : ""
                    ))
                }
            </div>
        </div>
    );
}

export default AllProductCard;