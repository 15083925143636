 export function getDayStartBorder() {
    const ts = + new Date()
    const date = new Date(ts)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const d = + new Date(year, month, day)
    return d
}

export function getWeekStartBorder() {
    const ts = + new Date()
    const date = new Date(ts)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const firstWeekDay = day - date.getDay();
    return + new Date(year, month, firstWeekDay)
}

export function getMonthStartBorder() {
    const ts = + new Date()
    const date = new Date(ts)
    const startMonthDay = + new Date(date.getFullYear(), date.getMonth(), 0)
    return startMonthDay
}

export function getYearStartBorder() {
    const ts = + new Date()
    const date = new Date(ts)
    const startYear = + new Date(date.getFullYear(), 0, 0)
    return startYear
}