import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendYooOrderToServer } from "../actions/purchaseActions";

import ProductCard from "../components/ProductCard/ProductCard";
import Loader from "../components/Loader";
import Message from "../components/Message";
import VkBtn from "../components/VkBtn";
import PolicyDisclaimer from "../policy/PolicyDisclaimer";
import AnimateBtn from "../components/AnimateBtn";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ProductScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const paramsProductId = match.params.id;

  const productInfo = useSelector((state) =>
    state.productList.products.find(
      (product) => product._id === paramsProductId
    )
  );
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const yoo = useSelector((state) => state.yoo);
  const { loading: yooLoading, /* error: yooError  */ } = yoo;

  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    !productInfo && history.push("/words");
    if (productInfo.words && productInfo.words.length !== 0) {
      history.push("/words");
    }
  }, [history, productInfo]);

  const pStyle = {
    marginTop: "0rem",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
  };

  const onPurchaseClick = () => {
    const sendOrder = (email) => {
      localStorage.setItem(
        "productForPaymentCheck",
        JSON.stringify(paramsProductId)
      );
      dispatch(sendYooOrderToServer(paramsProductId, email, history));
    }

    if (!email && !userInfo.email) {
      setMsg("Введите ваш Email для отправки чека")
    }
    else if (email && !userInfo.email) {
      const isEmailValid = validateEmail(email)
      if (isEmailValid) { sendOrder(email) }
      else setMsg("email написан с ошибкой, проверьте")
    }
    else {
      sendOrder(userInfo.email)
    }
  }

  const onBackClick = () => {
    window.scrollTo(0, 0);
    history.push("/words");
  }

  return (
    <>
      {productInfo && (
        <>
          <div className="content-scroll">
            <h1>Покупка набора</h1>
            <ProductCard
              img={productInfo.image}
              name={productInfo.name}
              price={productInfo.price}
              testWords={productInfo.testWords}
              length={productInfo.wordsLength}
            />
            {/* {yooError && <Message variant="danger">{yooError}</Message>} */}
            {
              yooLoading ? <Loader /> :
                userInfo && <div>
                  {
                    !userInfo.email && <>
                      <label style={{ width: "100%" }}>
                        <h6>Email</h6>
                      </label>
                      <input
                        value={email}
                        className="form-input"
                        type="email"
                        autoComplete="off"
                        placeholder="Введите email для получения чека"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {msg && <div className="mt-3"><Message variant="danger">{msg}</Message></div>}
                    </>
                  }
                  <div className="btn green" onClick={onPurchaseClick}>купить</div>
                </div>
            }
            {!userInfo && (
              <div>
                <p style={pStyle}>
                  Перед покупкой необходимо <br /> войти в игру через ВК
                </p>
                <PolicyDisclaimer />
                <VkBtn />
              </div>
            )}

          </div>
          <div className="btn-section">
            <div className="btn-inner-container">
              <AnimateBtn callback={onBackClick}>
                <div className="btn tomato">
                  Назад
                </div>
              </AnimateBtn>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductScreen;
