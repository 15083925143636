import React from "react";
import { Link } from "react-router-dom";
import AnimateBtn from "../components/AnimateBtn";

const OurApp = () => {

  return (
    <>
      <div className="content-scroll">

        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
          <h3 className='text-center'>Приложение</h3>
          <p className='rule-text text-center'>  Сайт имеет функциональность приложения. Добавьте его на свой домашний экран телефона, чтобы использовать его в полноэкранном режиме.    </p>
          <p className='rule-text text-center'> Приложение можно установить на iOS и Android.
            Зайдите в настройки браузера и добавьте страницу на экран домой.
          </p>

          <div className="py-1">
            <img style={{ margin: "0 auto" }} src="/images/landing/installPrompt.png" alt="установите приложение" />
          </div>
        </div>


      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn tomato" to="/settings">
              Назад
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default OurApp;
