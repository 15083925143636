import {
  REMOVE_TEAM,
  ADD_TEAM,
  EDIT_TEAM_COLOR,
  EDIT_TEAM_AVATAR,
  EDIT_TEAM_NAME,
  ADD_SCORE,
  CHANGE_PLAYING_STATUS,
  RESET_TEAMS,
  ADD_MEMBER,
  DEL_MEMBER,
  MIX_MEMBERS,
  SET_MEMBER_QUEUE,
  ADD_MEMBER_SCORE
} from "../constants/teamConstants";
import { v4 as uuidv4 } from "uuid";
import { TEAM_CONFIG } from "../configs/teamConfig";
import { getRandomInt } from "../utils/getRandomInt";
import { getLSTeamsInfo } from "../utils/getLSTeamsInfo"
import { setAlert } from "../actions/alertActions"

let currentItems;

export const delTeam = (id) => (dispatch) => {
  let lsItems = JSON.parse(localStorage.getItem("teamsInfo"));
  lsItems = lsItems.filter((team, idx) => idx !== id);
  /*   let lsItems = JSON.parse(localStorage.getItem("teamsInfo"));
    lsItems = lsItems.filter((team) => team.id !== id); */

  dispatch({
    type: REMOVE_TEAM,
    payload: lsItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(lsItems));
};

export const createTeam = (name, members) => (dispatch) => {
  const key = uuidv4();

  currentItems = getLSTeamsInfo();

  currentItems.push({
    memberQueue: 0,
    name: name,
    members,
    color: TEAM_CONFIG.colors[getRandomInt(TEAM_CONFIG.colors.length)],
    avatar: TEAM_CONFIG.avatars[getRandomInt(TEAM_CONFIG.avatars.length)],
    key,
    score: 0,
    isPlaying: true,
  });

  dispatch({
    type: ADD_TEAM,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const editColorTeam = (color, teamId) => (dispatch) => {
  currentItems = getLSTeamsInfo();
  currentItems[teamId].color = color;

  dispatch({
    type: EDIT_TEAM_COLOR,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const editAvatarTeam = (avatar, teamId) => (dispatch) => {
  currentItems = getLSTeamsInfo();
  currentItems[teamId].avatar = avatar;

  dispatch({
    type: EDIT_TEAM_AVATAR,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const editNameTeam = (name, teamId) => (dispatch) => {
  currentItems = getLSTeamsInfo();
  if (!name) { name = "Команда" }
  currentItems[teamId].name = name;

  dispatch({
    type: EDIT_TEAM_NAME,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const addMember = (memberName, teamId) => (dispatch) => {
  const newMember = {
    name: memberName.trim(),
    key: uuidv4(),
    score: 0
  }

  currentItems = getLSTeamsInfo();
  if (currentItems[teamId].members) { currentItems[teamId].members.push(newMember); }
  else {
    currentItems[teamId].members = [newMember]
  }

  dispatch({
    type: ADD_MEMBER,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const delMember = (memberKey, teamId) => (dispatch) => {

  currentItems = getLSTeamsInfo();
  const index = currentItems[teamId].members.findIndex(member => member.key === memberKey);
  currentItems[teamId].members.splice(index, 1)

  dispatch({
    type: DEL_MEMBER,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));
};

export const addScore = (teamId, score) => (dispatch, getState) => {
  const {
    teams: { teamsInfo },
  } = getState();
  if (teamsInfo[teamId].score + score < 0) {
    return
  }
  else {
    teamsInfo[teamId].score = teamsInfo[teamId].score + score;
  }

  dispatch({
    type: ADD_SCORE,
    payload: teamsInfo
  });
};

export const changePlayingStatus = (teamId, status) => (dispatch, getState) => {
  const {
    teams: { teamsInfo },
  } = getState();

  teamsInfo[teamId].isPlaying = status;

  dispatch({
    type: CHANGE_PLAYING_STATUS,
    payload: teamsInfo
  });
};

export const resetTeams = () => (dispatch, getState) => {
  const {
    teams: { teamsInfo },
  } = getState();

  teamsInfo.forEach(team => {
    team.isPlaying = true
    team.score = 0
    team.memberQueue = 0
    if (team.members && team.members.length > 0) {
      team.members.forEach(member => {
        member.score = 0;
      })
    }
  });

  dispatch({
    type: RESET_TEAMS,
    payload: teamsInfo
  });
};

export const mixMembers = (allMembers) => (dispatch) => {
  currentItems = getLSTeamsInfo();
  currentItems.forEach(team => { team.members = [] })
  
  let i = 0;
  while (allMembers.length !== 0) {
    const dice = getRandomInt(allMembers.length);
    currentItems[i].members.push(allMembers[dice])
    allMembers.splice(dice, 1);
    if (i === currentItems.length - 1) i = 0; else i++
  }

  let forSwap;
  let dice = getRandomInt(currentItems.length);
  forSwap = currentItems[dice].members;
  if (dice + 1 >= currentItems.length) {
    currentItems[dice].members = currentItems[0].members
    currentItems[0].members = forSwap
  }
  else {
    currentItems[dice].members = currentItems[dice + 1].members;
    currentItems[dice + 1].members = forSwap
  }

  dispatch({
    type: MIX_MEMBERS,
    payload: currentItems,
  });

  localStorage.setItem("teamsInfo", JSON.stringify(currentItems));

  dispatch(setAlert("Участники команд были перемешаны", "success"))
};

export const setMemberQueue = (teamIndex) => (dispatch, getState) => {
  const {
    teams: { teamsInfo },
  } = getState();
  currentItems = teamsInfo
  const currentMemberQueue = currentItems[teamIndex].memberQueue;

  if (currentMemberQueue + 1 === currentItems[teamIndex].members.length) {
    currentItems[teamIndex].memberQueue = 0
  }
  else {
    currentItems[teamIndex].memberQueue = currentMemberQueue + 1
  }

  dispatch({
    type: SET_MEMBER_QUEUE,
    payload: currentItems,
  });

};

export const addMemberScore = (teamIndex, score) => (dispatch, getState) => {
  const {
    teams: { teamsInfo },
  } = getState();
  currentItems = teamsInfo

  if (currentItems[teamIndex].members && currentItems[teamIndex].members.length > 0) {
    const memberIndex = currentItems[teamIndex].memberQueue
    currentItems[teamIndex].members[memberIndex].score += score

    dispatch({
      type: ADD_MEMBER_SCORE,
      payload: currentItems,
    });
  }
};