const devVkOauthUrl = "https://oauth.vk.com/authorize?client_id=7883233&display=page&redirect_uri=http://localhost:3000/login_vk&scope=email&response_type=code&v=5.131"

const prodVkOauthUrl = "https://oauth.vk.com/authorize?client_id=7883233&display=page&redirect_uri=https://alias-game.com/login_vk&scope=email&response_type=code&v=5.131"

const sec = 1000;
const min = sec * 60;  //60 000 - 1 min
const hr = min * 60;
const day = hr * 24;

export const productCacheDuration = day;
export const vkOauthUrl = process.env.NODE_ENV === "production" ? prodVkOauthUrl : devVkOauthUrl;