import React from 'react';
import "./Landing.css"
import RulesContent from './RulesContent';
import { Navbar, Container, Nav } from 'react-bootstrap';
import AnimateBtn from '../AnimateBtn';
import Helmet from "react-helmet";

import privacyPolicy from "../../policy/PrivacyPolicy.pdf"
import terms from "../../policy/TermsAndConditions.pdf"


function Landing(props) {
    const onPlayClick = () => {
        window.scrollTo(0, 0);
        props.viewedLandingHandler()
    }

    return (
        <>
            <Helmet>
                <title>Alias | Увлекательная игра для компании</title>
            </Helmet>
            <div className='landing'>
                <Navbar bg="light" variant="light" className='landing-navbar'>
                    <div className='landing-navbar-content'>
                        <Navbar.Brand href="#home"> <img className='alias-logo' style={{ width: "100px" }} src='images/alias_logo.png' alt="logo mini" /></Navbar.Brand>
                        <Nav className="me-auto">
                            <div className='landing-nav-links'>
                                <Nav.Link href="#rules" style={{ color: "#55595c" }}>Правила</Nav.Link>
                                <AnimateBtn callback={onPlayClick}>
                                    <Nav.Link style={{ color: "#55595c" }}>Играть</Nav.Link>
                                </AnimateBtn>
                            </div>
                        </Nav>
                    </div>
                </Navbar>
                <Container>
                    <div className='header-landing'>
                        <div className='flex-container left-side' style={{ flexDirection: "column" }}>
                            <img className='alias-logo' src='images/alias_logo.png' alt="logo big" />
                            <p className="text-center py-1" style={{ fontSize: "1rem" }}> Одна из лучших игр для компании <br /> Играйте командами по очереди <br /> Отгадывайте слова и получайте очки
                            </p>
                            <AnimateBtn callback={onPlayClick}>
                                <div style={{ marginTop: "0px" }} className='btn landing-btn'>Играть</div>
                            </AnimateBtn>
                            <h6 className='text-center mt-4'>Рекомендуем играть с телефона</h6>
                        </div>
                        <div className='right-side'>
                            <img className='main-pic' src='images/alias-main.png' alt="main" />
                        </div>
                    </div>
                </Container>

                <section id="rules" style={{ position: "relative", margin: "100px 0" }}>
                    <div className="custom-shape-divider-bottom-1641690437">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                    <div className='landing-theme-color'></div>
                    <div className='container rules'>
                        <h3 className='text-center py-3'>Правила</h3>
                        <RulesContent />
                    </div>

                    <div className="custom-shape-divider-top-1641691634">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </section>
            </div>
            <Container>
                <section className='play-section'>
                    <div id="app" className='install-app py-5'>
                        <div className='install-app-left'>
                            <h3 className='text-center'>Играйте как в браузере, так и в нашем бесплатном приложении</h3>
                            <p className='rule-text text-center'> Приложение можно установить на iOS и Android.
                                Зайдите в настройки браузера и добавьте страницу на экран домой
                            </p>
                            <p className='rule-text text-center'> Для работы требуется Интернет соединение
                            </p>
                        </div>
                        <img style={{ width: "100%", maxWidth: "380px", margin:"0 auto" }} src="/images/landing/installPrompt.png" alt="установите приложение" />
                    </div>

                    <div id="features" className='quirks' style={{ width: "100%" }}>
                        <h2 className='text-center' style={{overflowWrap: "break-word"}}>Особенности игры</h2>
                        <div className='bullits'>
                            <div className='bullit'>
                                <img className='bullit-img' src="/images/landing/time.png" alt="Время игры" />
                                <h6 className='text-center bullit-header'>Игра длится <br /> от 15 минут</h6>
                            </div>
                            <div className='bullit'>
                                <img className='bullit-img' src="/images/landing/people.png" alt="Игроки" />
                                <h6 className='text-center bullit-header'>2-15 <br /> игроков</h6>
                            </div>
                            <div className='bullit'>
                                <img className='bullit-img' src="/images/landing/layers.png" alt="Тематические наборы" />
                                <h6 className='text-center bullit-last-header'>10+ тематических <br /> наборов слов</h6>
                            </div>
                        </div>
                    </div>

                    {/* <AnimateBtn callback={onPlayClick}>
                        <div className='btn landing-btn start-landing-btn'>Играть</div>
                    </AnimateBtn> */}


                    <button className="cybr-btn" onClick={onPlayClick}>
                        Играть<span aria-hidden>_</span>
                        <span aria-hidden className="cybr-btn__glitch">Играть</span>
                        <span aria-hidden className="cybr-btn__tag">Alias</span>
                    </button>



                </section>
            </Container>


            <section className='footer-color' style={{ position: "relative", margin: "100px 0 0 0" }}>
                <div className="custom-shape-divider-bottom-1641690437">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill-footer"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill-footer"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill-footer"></path>
                    </svg>
                </div>
                <div className="container-fluid text-center text-md-left footer-color py-5">
                    <Container>
                        <div className="row">
                            <div className="col-md-6 mt-md-0 mt-3">
                                <img className='alias-logo mb-2' style={{ width: "100px" }} src='images/alias_logo.png' alt="logo mini" />
                                <p>Настольная игра для вашей весёлой компании. <br /> Объясняйте друзьям слова, но не называя их
                                </p>
                                <p> Возраст 9+ <br />
                                    Малое/умеренное количество тем,<br /> предназначенных только для взрослых</p>
                                <p>Цена: Бесплатно <br /> Есть возможность купить дополнительный игровой контент</p>
                            </div>

                            <hr className="clearfix w-100 d-md-none pb-0" />

                            <div className="col-md-3 mb-md-0 mb-3">
                                <h5 className="text-uppercase">Ссылки</h5>
                                <ul className="list-unstyled">
                                    <li > <Nav.Link style={{ padding: "0" }} className="blue-text" href="#rules">Правила</Nav.Link></li>
                                    <li > <Nav.Link style={{ padding: "0" }} className="blue-text" href="#app">Приложение</Nav.Link></li>
                                    <li > <Nav.Link style={{ padding: "0" }} className="blue-text" href="#features">Особенности</Nav.Link></li>
                                    <li> <a href={privacyPolicy} className="blue-text" target="_blank" rel="noopener noreferrer"> Политика конфиденциальности </a></li>
                                    <li><a href={terms} className="blue-text" target="_blank" rel="noopener noreferrer"> Пользовательское соглашение</a></li>
                                </ul>
                            </div>

                            <div className="col-md-3 mb-md-0 mb-3">
                                <h5 className="text-uppercase">О нас</h5>
                                <ul className="list-unstyled">
                                    <li><a href="https://vk.com/alias__game" className="blue-text" target="_blank" rel="noopener noreferrer"> Группа в ВК</a></li>
                                    <li>alias-sup@yandex.ru</li>
                                    <h5 className="mt-3 text-uppercase">Реквизиты</h5>
                                    <li>ИП Горяной Е.Е.</li>
                                    <li>ОГРНИП 320366800006566</li>
                                    <li>ИНН 366523902970</li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
            <div className="footer-copyright text-center footer-dark-color py-3">2022 |
                <a href="https://alias-game.com/"> alias-game.com</a>
            </div>

        </>
    );
}

export default Landing;


