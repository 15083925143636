import React from "react";
import "./Tabs.css";

const Tabs = ({ clickHandler, data, active }) => {
  const tabStyle = {
    width: 100 / data.length + "%",
  };
  return (
    <div className="tabs grey">
      {data.map((dat, idx) => (
        <div
          className={dat === active ? "tab-item active-tab" : "tab-item"}
          key={idx}
          style={tabStyle}
          onClick={() => {
            clickHandler(dat);
          }}
        >
          {dat}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
