import {
  ORDERS_SUMMARY_REQUEST,
  ORDERS_SUMMARY_SUCCESS,
  ORDERS_SUMMARY_FAIL,
  GET_ORDER_BY_USER_ID_REQUEST,
  GET_ORDER_BY_USER_ID_SUCCESS,
  GET_ORDER_BY_USER_ID_FAIL,
} from "../constants/orderConstants";

export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_SUMMARY_REQUEST:
      return { loading: true };
    case ORDERS_SUMMARY_SUCCESS:
      return { loading: false, orderSummary: action.payload };
    case ORDERS_SUMMARY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderByUserIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_BY_USER_ID_REQUEST:
      return { loading: true };
    case GET_ORDER_BY_USER_ID_SUCCESS:
      return { loading: false, ordersByUserId: action.payload };
    case GET_ORDER_BY_USER_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


