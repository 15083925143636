import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createTeam } from "../actions/teamActions";
import AnimateBtn from "../components/AnimateBtn";
import { v4 as uuidv4 } from 'uuid';
import "./EditTeamScreen.css";

const CreateTeamScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState();
  const [memberInput, setMemberInput] = useState("");
  const [members, setMembers] = useState([]);
  const [msg, setMsg] = useState();

  const onInputChange = (e) => {
    setInputData(e.target.value);
  };

  const onMemberInputChange = (e) => {
    setMemberInput(e.target.value);
  }

  const onCreate = () => {
    if (inputData) {
      msg && setMsg(null)
      dispatch(createTeam(inputData.trim(), members))
      history.push("/")
    } else {
      setMsg("Название команды не должно быть пустым");
    }
  };

  const onMemberAdd = () => {
    if (memberInput && memberInput !== "") {
      setMembers(prev => [...prev,
      {
        name: memberInput.trim(),
        key: uuidv4(),
        score: 0
      }])
      setMemberInput("")
    }
  }

  const onDelMemberClick = (id) => {
    setMembers(members.filter(member => id !== member.key))
  }

  const onBackClick = () => {
    window.scrollTo(0, 0);
    history.push("/");
  }



  return (
    <>
      <div className="content-scroll">
        <h1>Создать команду</h1>
        <Form className="mb-3">
          <Form.Group controlId="nameTeam">
            <Form.Label>Название команды *</Form.Label>
            <Form.Control
              className="form-input"
              type="search"
              placeholder="Введите название"
              onChange={onInputChange}
            ></Form.Control>
          </Form.Group>
          {msg && <p className="tomato">{msg}</p>}

          <Form.Group controlId="members">
            <Form.Label>Добавьте имена участников (необязательно)</Form.Label>
            <Form.Control
              value={memberInput}
              className="form-input"
              type="search"
              placeholder="Имя участника"
              onChange={onMemberInputChange}
            ></Form.Control>
            <AnimateBtn callback={onMemberAdd}>
              <div className="btn">Добавить участника</div>
            </AnimateBtn>
          </Form.Group>

        </Form>

        <Table striped bordered hover>
          <tbody>
            {
              members.map(member => <tr key={member.key}>
                <td className="td-name">{member.name}</td>
                <td onClick={() => onDelMemberClick(member.key)}
                  style={
                    {
                      textAlign: "center",
                      width: "10%",
                      cursor: "pointer"
                    }}>
                  Удалить
                </td>
              </tr>)
            }
          </tbody>
        </Table>
      </div>

      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn callback={onBackClick}>
            <div className="btn btn-secondary">
              <i className="fas fa-arrow-left "></i>
            </div>
          </AnimateBtn>
          <AnimateBtn callback={onCreate}>
            <div className="btn next-btn green">
              Создать команду
            </div>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default CreateTeamScreen;
