export const GENERATE_GAME_WORDS = "GENERATE_GAME_WORDS";
export const SET_ALREADY_WORDS = "SET_ALREADY_WORDS";
export const RESET_ALREADY_WORDS = "RESET_ALREADY_WORDS";
export const CHANGE_STATUS_ALREADY_WORDS = "CHANGE_STATUS_ALREADY_WORDS";
export const SET_IS_TIME_OVER = "SET_IS_TIME_OVER";
export const SET_IS_LAST_SECONDS = "SET_IS_LAST_SECONDS";
export const SET_FINAL_GAME_WORDS = "SET_FINAL_GAME_WORDS";
export const SET_IS_PAUSE = "SET_IS_PAUSE";
export const RESET_GAME_WORDS = "RESET_GAME_WORDS";
export const SET_INITIAL_AUDIO = "SET_INITIAL_AUDIO";
export const SET_INITIAL_AUDIO_CHANNEL_TWO = "SET_INITIAL_AUDIO_CHANNEL_TWO";
export const PLAY_AUDIO = "PLAY_AUDIO";
export const PLAY_AUDIO_CHANNEL_TWO = "PLAY_AUDIO_CHANNEL_TWO";

