import {
  ADD_TEAM,
  REMOVE_TEAM,
  EDIT_TEAM_COLOR,
  EDIT_TEAM_AVATAR,
  EDIT_TEAM_NAME,
  ADD_SCORE,
  CHANGE_PLAYING_STATUS,
  RESET_TEAMS,
  ADD_MEMBER,
  DEL_MEMBER,
  MIX_MEMBERS,
  SET_MEMBER_QUEUE,
  ADD_MEMBER_SCORE
} from "../constants/teamConstants";

function getInitialState() {
  return {
    teamsInfo: [],
  };
}

export const teamReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ADD_TEAM:
      return { ...state, teamsInfo: action.payload };
    case REMOVE_TEAM:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case EDIT_TEAM_COLOR:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case EDIT_TEAM_AVATAR:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case EDIT_TEAM_NAME:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case ADD_SCORE:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case CHANGE_PLAYING_STATUS:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case RESET_TEAMS:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case ADD_MEMBER:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case DEL_MEMBER:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case MIX_MEMBERS:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case SET_MEMBER_QUEUE:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    case ADD_MEMBER_SCORE:
      return {
        ...state,
        teamsInfo: action.payload,
      };
    default:
      return state;
  }
};
