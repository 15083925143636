import React from "react";
import "./Product.css";
import { useDispatch } from "react-redux";
import { selectProduct } from "../../actions/productActions";
/* import { Link } from "react-router-dom"; */

import CheckMark from "../CheckMark/CheckMark";
import AnimateBtn from "../AnimateBtn";

function Product({
  id,
  name,
  price,
  image,
  isAvailable,
  selected,
  history,
  adminMode = false,
  isRusPublished,
  isEngPublished
}) {
  const dispatch = useDispatch();

  const productStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
  };

  const onProductClickHandler = () => {
    if (isAvailable) {
      dispatch(selectProduct(id))
    }
    else {
      window.scrollTo(0, 0);
      if (adminMode) {
        history.push(`/adminn/product/edit/${id}`)
      }
      else {
        history.push(`product/${id}`)
      }
    }
  };

  return (
    <div>
      {isAvailable ? (
        <div
          className="product"
          style={productStyle}
          onClick={onProductClickHandler}
        >
          {selected && <CheckMark />}
          <h6>{name}</h6>
        </div>
      ) : (
        <AnimateBtn callback={onProductClickHandler}>
          <div
            className="product"
            style={productStyle}
          >
            <h6>{name}</h6>
            <p className="product-price">Купить за {price} ₽</p>
            {adminMode && <div style={{zIndex:"3"}}>
              {isRusPublished && <span className="published-packs-bage">Ru</span>}
              {isEngPublished && <span className="published-packs-bage">En</span>}
            </div>}
          </div>
        </AnimateBtn>
      )}
    </div>
  );
}

export default Product;
