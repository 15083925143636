import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  USER_PRODUCTS_REQUEST,
  USER_PRODUCTS_SUCCESS,
  USER_PRODUCTS_FAIL,
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  SELECT_PRODUCT,
  PRODUCT_LIST_LS,
  USER_PRODUCTS_LS,
  PRODUCTS_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET
} from "../constants/productConstants";

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case SELECT_PRODUCT:
      return { ...state, products: action.payload };
    case USER_PRODUCTS_REQUEST:
      return { loading: true, products: [] };
    case USER_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case USER_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_LIST_LS:
      return {
        loading: false,
        products: action.payload,
      };
    case USER_PRODUCTS_LS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCTS_RESET:
      return {
        products: []
      }
    default:
      return state;
  }
};

export const adminProductsReducer = (state = {products:[]}, action) => {
  switch (action.type) {
    case ADMIN_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case ADMIN_PRODUCTS_SUCCESS:
      return { loading: false, products: action.payload }
    case ADMIN_PRODUCTS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDetailsReducer = (state = {product:{}}, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload }
      case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}
