import React, { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TEAM_CONFIG } from "../configs/teamConfig";
import {
  addMember,
  editColorTeam,
  editAvatarTeam,
  editNameTeam,
  delMember,
} from "../actions/teamActions";
import AnimateBtn from "../components/AnimateBtn";

import "./EditTeamScreen.css";

const EditTeamScreen = ({ history, match }) => {
  const [memberInput, setMemberInput] = useState("");
  const paramsTeamId = match.params.id;
  const teamInfo = useSelector((state) =>
    state.teams.teamsInfo[paramsTeamId]
  );
  /*  const teamInfo = useSelector((state) =>
     state.teams.teamsInfo.find((team) => team.id === paramsTeamId)
   ); */

  const dispatch = useDispatch();
  const [inputData, setInputData] = useState(teamInfo.name);
  const [msg, setMsg] = useState();

  const onInputChange = (e) => {
    setInputData(e.target.value);
    msg && setMsg(null);
  };

  const onMemberInputChange = (e) => {
    setMemberInput(e.target.value);
  }

  const onMemberAdd = () => {
    if (memberInput && memberInput.trim() !== "") {
      dispatch(addMember(memberInput, paramsTeamId))
      setMemberInput("")
    }
  }

  const onDelMemberClick = (memberKey) => {
    dispatch(delMember(memberKey, paramsTeamId))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const delayDebounceFn = setTimeout(() => {
      dispatch(editNameTeam(inputData.trim(), paramsTeamId));
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [inputData, dispatch, teamInfo.id, paramsTeamId]);

  const onColorClick = (color) => {
    dispatch(editColorTeam(color, paramsTeamId));
  };

  const onAvatarClick = (avatar) => {
    dispatch(editAvatarTeam(avatar, paramsTeamId));
  };

  const onSaveClick = () => {
    if (inputData === "" || !inputData) {
      window.scrollTo(0, 0);
      setMsg("Название команды не должно быть пустым");
    }
    else {
      window.scrollTo(0, 0);
      history.push("/");
    }
  };

  let checkerStyle;
  if (teamInfo.color === "lemon") {
    checkerStyle = { color: "black" };
  } else checkerStyle = { color: "white" };


  return (
    <div>
      <div className="content-scroll">
        <h1>Редактор команды</h1>
        <Form>
          <Form.Group controlId="nameTeam">
            <Form.Label className="text-center" style={{ width: "100%" }}>
              <h6>Название команды</h6>
            </Form.Label>

            <input
              className="form-input"
              type="search"
              autoComplete="off"
              placeholder="Введите название"
              onChange={onInputChange}
              value={inputData} />

            {msg && <p className="tomato">{msg}</p>}
          </Form.Group>

          <Form.Group controlId="members">
            <Form.Label>Добавьте имена участников (необязательно)</Form.Label>
            <Form.Control
              value={memberInput}
              className="form-input"
              type="search"
              placeholder="Имя участника"
              onChange={onMemberInputChange}
            ></Form.Control>
            <AnimateBtn callback={onMemberAdd}>
              <div className="btn mb-2">Добавить участника</div>
            </AnimateBtn>
          </Form.Group>

          {teamInfo.members && teamInfo.members.length > 0 &&
            <Table striped bordered hover className="mb-4">
              <tbody >
                {
                  teamInfo.members.map(member => <tr key={member.key}>
                    <td className="td-name">
                      {member.name}
                    </td>
                    <td
                      onClick={() => onDelMemberClick(member.key)}
                      style={
                        {
                          textAlign: "center",
                          width: "10%",
                          cursor: "pointer"
                        }}>
                      Удалить
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          }


          <h6 className="text-center">Цвет фона</h6>
          <div className="three-grid-section">
            {TEAM_CONFIG.colors.map((color, idx) => (
              <div
                onClick={() => onColorClick(color)}
                key={idx}
                className={"team select-color " + color}
              >
                {color === teamInfo.color && (
                  <i style={checkerStyle} className="fas fa-check"></i>
                )}
              </div>
            ))}
          </div>

          <h6 className="text-center">Аватар</h6>

          <div className="three-grid-section">
            {TEAM_CONFIG.avatars.map((avatar, idx) => (
              <div
                onClick={() => onAvatarClick(avatar)}
                key={idx}
                className="edit-avatars"
              >
                <img
                  src={avatar}
                  style={{ display: "block", width: "3rem" }}
                  alt="avatar"
                />
                {avatar === teamInfo.avatar && (
                  <div
                    className={`selected-avatar animation-${teamInfo.color}`}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </Form>
      </div>

      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn callback={onSaveClick}>
            <div className="btn">
              Готово
            </div>
          </AnimateBtn>
        </div>
      </div>
    </div>
  );
};

export default EditTeamScreen;
