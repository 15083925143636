import React from 'react';
import privacyPolicy from "./PrivacyPolicy.pdf"
import terms from "./TermsAndConditions.pdf"

function PolicyLinks() {
    return (
        <p>
            <a href={privacyPolicy} className="blue-text" target="_blank" rel="noopener noreferrer"> Политика конфиденциальности </a>
            <br />
            <a href={terms} className="blue-text" target="_blank" rel="noopener noreferrer"> Пользовательское соглашение</a>
        </p>
    );
}

export default PolicyLinks;