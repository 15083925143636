import React from 'react';
import Message from './Message';

import { removeAlert } from "../actions/alertActions"
import { useDispatch } from "react-redux";
/* import { useHistory } from 'react-router-dom' */

function Alert({ alert }) {
    const dispatch = useDispatch();
/*     const history = useHistory()

    useEffect(() => {
        // в случае смены url удаляю алерт
        return history.listen((location) => {
            dispatch(removeAlert(alert.id))
        })
    }, [history, alert.id, dispatch]) */

    return (
        <div
            key={alert.id}
            onClick={() => dispatch(removeAlert(alert.id))}>
            <Message variant={`${alert.alertType}`}>
                {alert.msg}
            </Message>
        </div>
    );
}

export default Alert;