import axios from "axios";
import {
  YOO_ORDER_REQUEST,
  YOO_ORDER_SUCCESS,
  YOO_ORDER_FAIL,
  YOO_ORDER_CHECK_REQUEST,
  YOO_ORDER_CHECK_SUCCESS,
  YOO_ORDER_CHECK_FAIL,
  YOO_ORDER_CHECK_BUYALL_REQUEST,
  YOO_ORDER_CHECK_BUYALL_SUCCESS,
  YOO_ORDER_CHECK_BUYALL_FAIL
} from "../constants/purchaseConstants";
import { USER_UPDATE_DATA_LOCALY } from "../constants/userConstants";
import { setAlert } from "./alertActions";
import { delProductsCache } from "../utils/cache";
import { getUserProducts } from "./productActions";

const transformLanguage = (language) => {
  if (language === "Русский") { return "russian" }
  else { return language.toLowerCase() }
}


const returnedYooDataHandler = (data, userInfo, dispatch, history) => {
  if (data.confirmation_url && history.location.pathname === "/words") { return };

  if (data.confirmation_url) { window.location.replace(data.confirmation_url); }
  else if (data.status === "success" && !userInfo.hasPaidProducts) {
    localStorage.removeItem("productForPaymentCheck")
    delProductsCache()
    const updatedUserInfo = { ...userInfo, hasPaidProducts: true };
    dispatch({
      type: USER_UPDATE_DATA_LOCALY,
      payload: updatedUserInfo
    });
    localStorage.setItem("userInfo", JSON.stringify(updatedUserInfo));
    dispatch(getUserProducts())
    dispatch(setAlert(`Набор ${data.productName} куплен!`, "success"));
    history.push("/words")
  }

  if (data.status === "success" && userInfo.hasPaidProducts) {
    localStorage.removeItem("productForPaymentCheck")
    delProductsCache();
    dispatch(getUserProducts())
    dispatch(setAlert(`Набор ${data.productName} куплен!`, "success"));
    history.push("/words")
  }

}


export const sendYooOrderToServer = (productId, email, history) => async (dispatch, getState) => {
  delProductsCache()
  try {
    dispatch({
      type: YOO_ORDER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      settings: { language },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      params: {
        lang: transformLanguage(language)
      }
    };

    const { data } = await axios.post(
      "/api/orders/yooOrder",
      { productId, email },
      config
    );

    returnedYooDataHandler(data, userInfo, dispatch, history)

    dispatch({
      type: YOO_ORDER_SUCCESS,
      payload: data,
    });

  } catch (error) {

    localStorage.removeItem("productForPaymentCheck")

    dispatch({
      type: YOO_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

  }
}

export const sendBuyAllYooOrderToServer = (email, history) => async (dispatch, getState) => {
  delProductsCache()
  try {
    dispatch({
      type: YOO_ORDER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      settings: { language },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      params: {
        lang: transformLanguage(language)
      }
    };

    const { data } = await axios.post("/api/orders/buyAllProducts", {email}, config);

    returnedYooDataHandler(data, userInfo, dispatch, history)

    dispatch({
      type: YOO_ORDER_SUCCESS,
      payload: data,
    });

  } catch (error) {

    localStorage.removeItem("productForPaymentCheck")

    dispatch({
      type: YOO_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}



export const checkYooOrder = (productId, history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YOO_ORDER_CHECK_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      settings: { language },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      params: {
        lang: transformLanguage(language)
      }
    };

    const { data } = await axios.post(
      "/api/orders/yooOrderCheck",
      { productId },
      config
    );

    /* console.log(data, "checked data") */
    returnedYooDataHandler(data, userInfo, dispatch, history)

    dispatch({
      type: YOO_ORDER_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    localStorage.removeItem("productForPaymentCheck")
    dispatch({
      type: YOO_ORDER_CHECK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const checkYooOrderBuyAll = (history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YOO_ORDER_CHECK_BUYALL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      settings: { language },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      params: {
        lang: transformLanguage(language)
      }
    };

    const { data } = await axios.post(
      "/api/orders/buyAllProductsCheck",
      {},
      config
    );

    /* console.log(data, "checked data") */
    returnedYooDataHandler(data, userInfo, dispatch, history)

    dispatch({
      type: YOO_ORDER_CHECK_BUYALL_SUCCESS,
      payload: data,
    });
  } catch (error) {

    localStorage.removeItem("productForPaymentCheck")

    dispatch({
      type: YOO_ORDER_CHECK_BUYALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}


