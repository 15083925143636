import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendBuyAllYooOrderToServer } from "../actions/purchaseActions";

import AllProductCard from "../components/ProductCard/AllProductCard";
import Loader from "../components/Loader";
import Message from "../components/Message";
import VkBtn from "../components/VkBtn";
import PolicyDisclaimer from "../policy/PolicyDisclaimer";
import AnimateBtn from "../components/AnimateBtn";

import useCountAllProductsDiscount from "../hooks/useCountAllProductsDiscount";

function sumLength(arrOfObj) {
  let initialValue = 0;
  let sum = arrOfObj.reduce(
    (accumulator, currentValue) => accumulator + currentValue.wordsLength,
    initialValue
  );
  return sum;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const BuyAllScreen = ({ history }) => {
  const dispatch = useDispatch();
  /*   const products = useSelector(state => state.productList.products.filter(product => !product.words)); */

  const products = useSelector(state => state.productList.products);
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const { totalPrice, totalPriceDiscount } = useCountAllProductsDiscount(products, 10)
  const yoo = useSelector((state) => state.yoo);
  const { loading: yooLoading, /* error: yooError */ } = yoo;

  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (totalPrice === 0) history.push("/words");
  }, [history, totalPrice]);

  const pStyle = {
    marginTop: "0rem",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
  };

  const onPurchaseClick = () => {
    const sendOrder = (email) => {
      localStorage.setItem(
        "productForPaymentCheck",
        JSON.stringify("buyAll")
      );
      dispatch(sendBuyAllYooOrderToServer(email, history));
    }

    if (!email && !userInfo.email) {
      setMsg("Введите ваш Email для отправки чека")
    }
    else if (email && !userInfo.email) {
      const isEmailValid = validateEmail(email)
      if (isEmailValid) { sendOrder(email) }
      else setMsg("email написан с ошибкой, проверьте")
    }
    else {
      sendOrder(userInfo.email)
    }
  }

  const onBackClick = () => {
    window.scrollTo(0, 0);
    history.push("/words");
  }

  return (
    <>
      {products && products.length > 0 && (
        <>
          <div className="content-scroll">
            <h1>Покупка всех наборов</h1>
            <AllProductCard
              img={'/images/products/buyall.jpeg'}
              name={"Все наборы"}
              price={totalPrice}
              discountPercent={10}
              discountPrice={totalPriceDiscount}
              length={sumLength(products.filter(p => !p.words))}
              productsNames={products.map(product => { if (!product.words) { return product.name } else return null })}
            />

            {/* {yooError && <Message variant="danger">{yooError}</Message>} */}

            {
              yooLoading ? <Loader /> :
                userInfo && <div>
                  {
                    !userInfo.email && <>
                      <label style={{ width: "100%" }}>
                        <h6>Email</h6>
                      </label>
                      <input
                        value={email}
                        className="form-input"
                        type="email"
                        autoComplete="off"
                        placeholder="Введите email для получения чека"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {msg && <div className="mt-3"><Message variant="danger">{msg}</Message></div>}
                    </>
                  }
                  <div className="btn green" onClick={onPurchaseClick}>купить со скидкой</div>
                </div>
            }

            {!userInfo && (
              <div>
                <p style={pStyle}>
                  Перед покупкой необходимо <br /> войти в игру через ВК
                </p>
                <PolicyDisclaimer />
                <VkBtn />
              </div>
            )}

          </div>
          <div className="btn-section">
            <div className="btn-inner-container">
              <AnimateBtn callback={onBackClick}>
                <div className="btn tomato">
                  Назад
                </div>
              </AnimateBtn>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BuyAllScreen;
