export const TEAM_CONFIG = {
  colors: [
    "tomato",
    "blue",
    "iris",
    "jade",
    "orange",
    "lemon",
    "dark-blue",
    "pink",
    "cyan",
  ],
  avatars: [
    "/images/avatars/a1.png",
    "/images/avatars/a2.png",
    "/images/avatars/a3.png",
    "/images/avatars/a4.png",
    "/images/avatars/a5.png",
    "/images/avatars/a6.png",
    "/images/avatars/a7.png",
    "/images/avatars/a8.png",
    "/images/avatars/a9.png",
    "/images/avatars/a10.png",
    "/images/avatars/a11.png",
    "/images/avatars/a12.png",
  ],
};
