import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userListReducer,
  userToogleProductReducer,
  userSummary
} from "./reducers/userReducers";
import { teamReducer } from "./reducers/teamReducer";
import { settingsReducer } from "./reducers/settingsReducer";
import {
  adminProductsReducer,
  productListReducer,
  productDetailsReducer,
  productUpdateReducer,
  productCreateReducer
} from "./reducers/productReducer";
import { purchaseReducer } from "./reducers/purchaseReducer";
import queue from "./reducers/queueReducer";
import alertReducer from "./reducers/alertReducer";
import gameReducer from "./reducers/gameReducer";
import { orderReducer, orderByUserIdReducer } from "./reducers/orderReducer"

import { v4 as uuidv4 } from "uuid";

const reducer = combineReducers({
  gameReducer,
  queue,
  yoo: purchaseReducer,
  orderSummary: orderReducer,
  userOrders: orderByUserIdReducer,
  userSummary,
  userLogin: userLoginReducer,
  teams: teamReducer,
  settings: settingsReducer,
  productList: productListReducer,
  adminProducts: adminProductsReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  alertReducer,
  userList: userListReducer,
  userToogleProduct: userToogleProductReducer,
});

//invalidate cache
const productList = localStorage.getItem("productList")
  ? JSON.parse(localStorage.getItem("productList"))
  : [];
if (productList && productList.expire <= Date.now()) {
  localStorage.removeItem("productList");
}

const userProducts = localStorage.getItem("userProducts")
  ? JSON.parse(localStorage.getItem("userProducts"))
  : null;
if (userProducts && userProducts.expire <= Date.now()) {
  localStorage.removeItem("userProducts");
}
//end invalidate cache

let initialReduxProducts;
if (userProducts) {
  initialReduxProducts = userProducts.products;
}
else if (productList) {
  initialReduxProducts = productList.products;
}
else { initialReduxProducts = [] }


if (!localStorage.getItem("teamsInfo")) {
  const genId = () => {
    return uuidv4();
  };
  localStorage.setItem(
    "teamsInfo",
    JSON.stringify([
      {
        memberQueue: 0,
        name: "Красные",
        members: [],
        color: "tomato",
        avatar: "/images/avatars/a1.png",
        key: genId(),
        score: 0,
        isPlaying: true,
      },
      {
        memberQueue: 0,
        name: "Синие",
        members: [],
        color: "blue",
        avatar: "/images/avatars/a3.png",
        key: genId(),
        score: 0,
        isPlaying: true,
      },
    ])
  );
}

if (!localStorage.getItem("settings")) {
  let userLang = navigator.language || navigator.userLanguage;
  if (userLang === "ru-RU" || userLang === "ru") userLang = "Русский";
  else userLang = "English"

  localStorage.setItem(
    "settings",
    JSON.stringify({
      roundDuration: 60,
      scoreToWin: 50,
      minusScore: true,
      language: userLang,
      viewedLanding: false
    })
  );
}

const teamsInfoFromStorage = localStorage.getItem("teamsInfo")
  ? JSON.parse(localStorage.getItem("teamsInfo"))
  : null;

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const settingsFromStorage = localStorage.getItem("settings")
  ? JSON.parse(localStorage.getItem("settings"))
  : null;

const initialState = {
  teams: { teamsInfo: teamsInfoFromStorage },
  userLogin: { userInfo: userInfoFromStorage },
  settings: settingsFromStorage,
  productList: { products: initialReduxProducts },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
