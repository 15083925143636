import axios from "axios";
import {
  USER_LOGIN_VK_FAIL,
  USER_LOGIN_VK_REQUEST,
  USER_LOGIN_VK_SUCCESS,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_TOOGLE_PRODUCT_REQUEST,
  USER_TOOGLE_PRODUCT_SUCCESS,
  USER_TOOGLE_PRODUCT_FAIL,
  USERS_SUMMARY_REQUEST,
  USERS_SUMMARY_SUCCESS,
  USERS_SUMMARY_FAIL,
} from "../constants/userConstants";

import {
  getDayStartBorder,
  getWeekStartBorder,
  getMonthStartBorder,
  getYearStartBorder
} from "../utils/getStartTimeStamp";

import { PRODUCTS_RESET } from "../constants/productConstants";
import { setAlert } from "./alertActions";
import { delProductsCache } from "../utils/cache";

export const sendVKCodeToServer = (vkCode) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_VK_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/users/loginVK",
      { vkCode },
      config
    );

    dispatch({
      type: USER_LOGIN_VK_SUCCESS,
      payload: data,
    });

    let fName = "";
    let lName = "";
    if (data) {
      fName = data.first_name ? data.first_name : "";
      lName = data.last_name ? data.last_name : "";
    }


    dispatch(setAlert(`Вы вошли как ${fName} ${lName}. Выйти можно в настройках`, "success"));

    delProductsCache();
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_VK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  delProductsCache();
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: PRODUCTS_RESET });
  dispatch({ type: USER_LIST_RESET })
};

export const listUsers = (keyword = '', pageNumber = '', searchField = '') => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users?keyword=${keyword}&pageNumber=${pageNumber}&searchField=${searchField}`, config)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    })
  }
}



export const toogleUserProduct = (reqUserId, reqProductId, keyword, searchField) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_TOOGLE_PRODUCT_REQUEST,
    })

    const {
      userLogin: { userInfo },
      userList: { page }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const obj = {
      reqUserId,
      reqProductId
    }

    await axios.put('/api/users/toogleProduct', obj, config);

    dispatch({
      type: USER_TOOGLE_PRODUCT_SUCCESS,
    })
    dispatch(listUsers(keyword, page, searchField));

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_TOOGLE_PRODUCT_FAIL,
      payload: message,
    })
  }
}

export const getUsersSummary = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USERS_SUMMARY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const obj = {
      startDay: getDayStartBorder(),
      startWeek: getWeekStartBorder(),
      startMonth: getMonthStartBorder(),
      startYear: getYearStartBorder(),
    }

    const { data } = await axios.post(
      "/api/users/summary",
      obj,
      config
    );

    dispatch({
      type: USERS_SUMMARY_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: USERS_SUMMARY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
