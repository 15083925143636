export const YOO_ORDER_REQUEST = "YOO_ORDER_REQUEST";
export const YOO_ORDER_SUCCESS = "YOO_ORDER_SUCCESS";
export const YOO_ORDER_FAIL = "YOO_ORDER_FAIL";

export const YOO_ORDER_CHECK_REQUEST = "YOO_ORDER_CHECK_REQUEST";
export const YOO_ORDER_CHECK_SUCCESS = "YOO_ORDER_CHECK_SUCCESS";
export const YOO_ORDER_CHECK_FAIL = "YOO_ORDER_CHECK_FAIL";

export const YOO_ORDER_CHECK_BUYALL_REQUEST = "YOO_ORDER_CHECK_BUYALL_REQUEST";
export const YOO_ORDER_CHECK_BUYALL_SUCCESS = "YOO_ORDER_CHECK_BUYALL_SUCCESS";
export const YOO_ORDER_CHECK_BUYALL_FAIL = "YOO_ORDER_CHECK_BUYALL_FAIL";

