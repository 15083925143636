import React from "react";
import { Link } from "react-router-dom";
import AnimateBtn from "../components/AnimateBtn";

const NotFound = () => {

  return (
    <>
      <div className="content-scroll">

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <h3 className='text-center'>404 Страница не найдена</h3>

          <div className="py-1">
            <img style={{width:"100%",maxWidth:"500px"}} src="/images/notFound.svg" alt="Страница не найдена" />
          </div>
        </div>


      </div>
      <div className="btn-section">
        <div className="btn-inner-container">
          <AnimateBtn>
            <Link className="btn" to="/">
              Главная
            </Link>
          </AnimateBtn>
        </div>
      </div>
    </>
  );
};

export default NotFound;
